import React from 'react'
import PropTypes from 'prop-types'
import LaddaButton, { S, EXPAND_LEFT } from 'react-ladda-button'

import './Button.css'
import './DefaultButton.scss'
import theme from '../../shared/theme'

const Button = ({
  isLoading,
  children,
  disabled = false,
  onClick,
  color = theme.DARKBLUE,
  size = S,
  style = EXPAND_LEFT,
  spinnerSize = 25,
  spinnerColor = '#fff',
  spinnerLine = 25,
  ...rest
}) => {
  return (
    <LaddaButton
      loading={isLoading}
      onClick={onClick}
      data-color={color}
      data-size={size}
      data-style={style}
      data-spinner-size={spinnerSize}
      data-spinner-color={spinnerColor}
      data-spinner-lines={spinnerLine}
      disabled={disabled}
      {...rest}
    >
      {children}
    </LaddaButton>
  )
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default Button
