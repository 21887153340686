import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { fromZonedTime } from 'date-fns-tz'
import { Helmet } from 'react-helmet'
import { detectAnyAdblocker } from 'just-detect-adblock'

import { OrderDetailAPI } from '../../api/DesignOrder/OrderDetail'
import toastCenter, { toastMessageType } from '../../shared/toastCenter'
import * as actions from '../../store/actions/index'
import Annotation from '../../components/Annotation/Annotation'
import { AttachmentVersionAPI } from '../../api/DesignOrder/AttachmentVersion'
import { GetAllAnnotationAPI } from '../../api/Annotation/GetAllAnnotation'
import { DeleteAttachmentVersionAPI } from '../../api/Annotation/DeleteAttachmentVersion'
import { CreateTopicAPI } from '../../api/Annotation/CreateTopic'
import FormHandler from '../../factory/FormHandler'
import { DeleteTopicAPI } from '../../api/Annotation/DeleteTopic'
import { DeleteCommentAPI } from '../../api/Annotation/DeleteComment'
import { AddCommentAPI } from '../../api/Annotation/AddComment'
import { EditCommentAPI } from '../../api/Annotation/EditComment'
import {
  EditAnnotationAPI,
  typeAnnotationEdit,
} from '../../api/Annotation/EditAnnotation'
import { annotationService } from '../../services/annotationService/annotationService'
import { createTopicService } from '../../services/annotationService/createTopicService'
import { commentTopicService } from '../../services/annotationService/commentTopicService'
import { deleteCommentService } from '../../services/annotationService/deleteCommentService'
import { editCommentService } from '../../services/annotationService/editCommentService'
import { deleteTopicService } from '../../services/annotationService/deleteTopicService'
import { editTopicService } from '../../services/annotationService/editTopicService'
import { annotationResolveService } from '../../services/annotationService/annotationResolveService'
import { attachmentVersionService } from '../../services/attachmentVersionService/attachmentVersionService'
import { socketRemoveEventListener } from '../../socket/socket'
import socketEvent from '../../socket/socketEvent'
import branding from '../../shared/branding'
import { MentionByOrderAPI } from '../../api/Comment/MentionByOrder'
import {
  reviewerAbilityStruct,
  attachmentTypeStartFromStruct,
} from '../../components/Annotation/shared/constants'
import clientLogin from '../../shared/clientLogin'
import USER_ROLE from '../../shared/userRole'
import { designOrderService } from '../../services/designOrderService/designOrderService'
import { OrderDetailEditProgressAPI } from '../../api/DesignOrder/OrderDetailEditProgress'
import Modal from '../../components/Modal/Modal'
import Textarea from '../../components/Form/Textarea/Textarea'
import Logo from '../../components/Logo/Logo'
import validator from 'validator'
import {
  CreativeManagerApproveAttachmentAPI,
  CreativeManagerReviseAttachmentAPI,
} from '../../api/DesignOrder/OrderDetailCreativeManagerReviewAttachmentAPI'
import { defineAbilitiesFor } from '../../shared/ability'
import { AbilityContext } from '../../context/abilityContext'
import { useQuery } from '../../shared/utility'
import AdBlockWarning from '../../components/Annotation/components/AdBlockWarning/AdBlockWarning'
import { CreateTopicSilentlyAPI } from '../../api/Annotation/CreateTopicSilently'

const maxLengthReason = 255

// const toNumber = (arg = '') => {
//   return parseFloat(arg.replace(/^(v)/g, ''))
// }

const AnnotationContainer = ({
  apiStart,
  apiStop,
  user,
  onPromptReasonShow,
  onPromptReasonHide,
  promptReason,
  userRole,
  routeRemoveRedirect,
}) => {
  const { orderId, attachmentId } = useParams()
  const navigate = useNavigate()
  //#region State
  const [isLoading, setIsLoading] = useState(true)
  const [annotations, setAnnotations] = useState({ internal: [], public: [] })
  const [isLoadAnnotation, setIsLoadAnnotation] = useState(true)
  const [isFileExist, setIsFileExist] = useState(false)
  const [versions, setVersions] = useState([])
  const [versionIndex, setVersionIndex] = useState(0) // by default set 0
  const [listMentions, setListMentions] = useState([])
  const [orderDetail, setOrderDetail] = useState(null)
  const [reviewerAbility, setReviewerAbility] = useState()
  const [isEmptyReason, setIsEmptyReason] = useState(true)
  const [stateReason, setStateReason] = useState('')
  const [charsLeft, setCharsLeft] = useState(maxLengthReason)
  const [isPreview, setIsPreview] = useState(false)
  const [isInternalTabDisabled, setIsInternalTabDisabled] = useState(false)
  const [isPublicTabDisabled, setIsPublicTabDisabled] = useState(false)
  const [attachmentTypeStartFrom, setAttachmentTypeStartFrom] = useState(
    attachmentTypeStartFromStruct.SUPPLIED_FILE
  )
  const [byteLength, setByteLength] = useState(0)
  const [isShowAdBlockerWarning, setIsShowAdBlockerWarning] = useState(false)
  const [downloadWithWatermark, setDownloadWithWatermark] = useState(false)
  //#endregion

  //#region Variable
  const publicQuery = new URLSearchParams(window.location.search).get('public')
  const clientId = localStorage.getItem('clientId')
  const ability = defineAbilitiesFor(userRole)

  let query = useQuery()
  const queryVersionId = query.get('versionId')
  const queryCommentId = query.get('commentId')
  const queryPublic = query.get('public')
  //#endregion

  //#region Use Effect
  useEffect(() => {
    detectAnyAdblocker().then((detected) => {
      if (detected) {
        setIsShowAdBlockerWarning(true)
      }
    })

    const socketFnReloadVersionDetail = (_) => {
      annotationService.emitReload({ isReload: true, versionId: null })
    }

    const socketFnReloadVersionList = (_) => {
      attachmentVersionService.emitReload(true)
    }

    routeRemoveRedirect()

    return () => {
      socketRemoveEventListener(
        socketEvent.RELOAD_VERSION_DETAIL,
        socketFnReloadVersionDetail
      )
      socketRemoveEventListener(
        socketEvent.RELOAD_VERSION_LIST,
        socketFnReloadVersionList
      )
    }
  }, [])

  useEffect(() => {
    // check file exist at S3
    if (versionIndex > -1 && versions.length > 0) {
      setIsLoading(true)

      if (versions[versionIndex]) {
        const currentVersion = versions[versionIndex]
        setByteLength(parseInt(currentVersion.fileMeta.fileSize, 10))
      } else {
        let indexVersion = versionIndex
        versions.forEach((version, key) => {
          indexVersion = key
        })
        const currentVersion = versions[indexVersion]
        setByteLength(parseInt(currentVersion.fileMeta.fileSize, 10))
      }

      setIsLoading(false)

      // if (!currentVersion.fileMeta.isAccessible) {
      //   setIsLoading(false)
      //   toastCenter.message(
      //     'Not Found!',
      //     'File is not found or failed to load. Please try again later.',
      //     toastMessageType.DANGER
      //   )
      //   navigate('/')

      //   return () => {}
      // }
    }

    return () => {}
  }, [versions, versionIndex])

  // first load and load order detail to check file exist
  useEffect(() => {
    apiStart()
    if (orderId && attachmentId && navigate) {
      const orderDetailApi = new OrderDetailAPI()

      const onNext = (response) => {
        if (response.success) {
          const currentOrder = response.data
          let suppliedFiles = []
          let internalReview = []
          let customerReview = []

          if (currentOrder.attachments.customerReview) {
            customerReview = [...currentOrder.attachments.customerReview]
          }
          if (currentOrder.attachments.suppliedFiles) {
            suppliedFiles = [...currentOrder.attachments.suppliedFiles]
          }
          if (currentOrder.attachments.internalReview) {
            internalReview = [...currentOrder.attachments.internalReview]
          }

          const attachments = [
            ...customerReview,
            ...suppliedFiles,
            ...internalReview,
          ]
          const fileIndex = attachments.findIndex((x) => x.id === attachmentId)

          if (fileIndex === -1) {
            navigate('/')
          } else {
            setIsInternalTabDisabled(
              attachments[fileIndex].isDisabledInternalTabAnnotation
            )
            setIsPublicTabDisabled(
              attachments[fileIndex].isDisabledPublicTabAnnotation
            )

            setIsPreview(
              currentOrder.progress === 'approved' ||
                currentOrder.progress === 'cancelled'
            )
            setAttachmentTypeStartFrom(
              attachments[fileIndex].attachmentTypeStartFrom
            )
            setIsFileExist(true)
            setOrderDetail(currentOrder)
            setDownloadWithWatermark(currentOrder.downloadWithWatermark)
          }
        } else {
          navigate('/')
        }
      }

      const onComplete = () => {
        apiStop()
      }

      const onError = () => {
        apiStop()
        setIsLoading(false)
        toastCenter.message(
          'Permission Denied!',
          'You are not allowed to access this file!',
          toastMessageType.DANGER
        )
        navigate('/')
      }

      orderDetailApi.subscribe(orderId, onNext, onComplete, onError)

      const mentionByOrder = new MentionByOrderAPI()

      const onNextMention = (response) => {
        if (response.success) {
          setListMentions(response.data)
        } else {
          toastCenter.message(
            'Failed',
            response.message,
            toastMessageType.WARNING
          )
        }
      }
      const onCompleteMention = () => {}
      const onErrorMention = () => {
        toastCenter.messageServerError()
      }

      mentionByOrder.subscribe(
        orderId,
        onNextMention,
        onCompleteMention,
        onErrorMention
      )

      return () => {
        orderDetailApi.unsubscribe()
        mentionByOrder.unsubscribe()
      }
    }

    return () => {}
  }, [apiStart, apiStop, attachmentId, navigate, orderId])

  useEffect(() => {
    if (
      user &&
      orderDetail &&
      orderDetail.attachments &&
      orderDetail.attachments.customerReview &&
      attachmentId
    ) {
      const clientId = localStorage.getItem('clientId')
      const hasInReviewFileIndex =
        orderDetail.attachments.customerReview.findIndex(
          (x) => x.id === attachmentId
        )

      // check file is in review state and order status in review state and type login is customer
      if (
        hasInReviewFileIndex > -1 &&
        orderDetail.progress === 'in-review' &&
        clientId === clientLogin.CUSTOMER
      ) {
        switch (user.role) {
          case USER_ROLE.OWNER:
            // ability to all order
            setReviewerAbility([
              reviewerAbilityStruct.MAKE_CHANGES,
              reviewerAbilityStruct.PUT_ON_HOLD,
              reviewerAbilityStruct.STAY_IN_REVIEW,
              reviewerAbilityStruct.ORDER_APPROVED,
            ])
            break
          case USER_ROLE.STAFF:
            // check has current order creator
            // check has approver and collaborator
            // check has approver
            // check has collaborator order
            // check has not approver and has not order creator
            if (
              orderDetail.creator.id === user.id &&
              orderDetail.userAbility === 'creator'
            ) {
              setReviewerAbility([
                reviewerAbilityStruct.MAKE_CHANGES,
                reviewerAbilityStruct.PUT_ON_HOLD,
                reviewerAbilityStruct.STAY_IN_REVIEW,
                reviewerAbilityStruct.ORDER_APPROVED,
              ])
            } else if (
              user.isApprover !== undefined &&
              user.isApprover === true &&
              orderDetail.collaborators.findIndex((x) => x.id === user.id) >
                -1 &&
              orderDetail.userAbility === 'collaborator'
            ) {
              setReviewerAbility([
                reviewerAbilityStruct.MAKE_CHANGES,
                reviewerAbilityStruct.PUT_ON_HOLD,
                reviewerAbilityStruct.STAY_IN_REVIEW,
                reviewerAbilityStruct.ORDER_APPROVED,
              ])
            } else if (
              user.isApprover !== undefined &&
              user.isApprover === true
            ) {
              setReviewerAbility([reviewerAbilityStruct.ORDER_APPROVED])
            } else if (
              orderDetail.collaborators.findIndex((x) => x.id === user.id) >
                -1 &&
              orderDetail.userAbility === 'collaborator'
            ) {
              setReviewerAbility([
                reviewerAbilityStruct.MAKE_CHANGES,
                reviewerAbilityStruct.PUT_ON_HOLD,
                reviewerAbilityStruct.STAY_IN_REVIEW,
              ])
            } else {
              setReviewerAbility([])
            }

            break
          default:
            break
        }
      }
    }
  }, [user, orderDetail, attachmentId])

  const getStartingScreenPublic = useCallback(() => {
    if (!orderDetail) {
      return true
    }

    if (queryPublic !== null) {
      const isOpenViewPublicTab = queryPublic === 'true'

      return isOpenViewPublicTab
    }

    let suppliedFiles = []
    let internalReview = []
    let customerReview = []

    if (orderDetail.attachments.customerReview) {
      customerReview = [...orderDetail.attachments.customerReview]
    }
    if (orderDetail.attachments.suppliedFiles) {
      suppliedFiles = [...orderDetail.attachments.suppliedFiles]
    }
    if (orderDetail.attachments.internalReview) {
      internalReview = [...orderDetail.attachments.internalReview]
    }

    const attachments = [...customerReview, ...suppliedFiles, ...internalReview]
    const fileIndex = attachments.findIndex((x) => x.id === attachmentId)

    if (fileIndex > -1) {
      return attachments[fileIndex].startingTab === 'public'
    }

    return true
  }, [attachmentId, orderDetail, queryPublic])

  // load annotation
  const loadAnnotation = useCallback(
    (currentVersion) => {
      if (currentVersion) {
        setIsLoadAnnotation(true)

        const versionId = currentVersion.id
        const getAllAnnotationApi = new GetAllAnnotationAPI()
        const onNext = (response) => {
          if (response.success) {
            setAnnotations(response.data)

            const isStartingScreenPublic = getStartingScreenPublic()

            if (clientId === clientLogin.ADMIN) {
              if (isStartingScreenPublic) {
                setIsPreview(currentVersion.isDisabledAnnotation)
              } else {
                setIsPreview(currentVersion.isDisabledInternalAnnotation)
              }
            } else {
              setIsPreview(currentVersion.isDisabledAnnotation)
            }
          } else {
            toastCenter.message(
              'Failed',
              response.message,
              toastMessageType.WARNING
            )
          }
        }

        const onComplete = () => {
          setIsLoadAnnotation(false)
        }

        const onError = () => {
          toastCenter.messageServerError()
        }

        getAllAnnotationApi.subscribe(
          orderId,
          attachmentId,
          versionId,
          onNext,
          onComplete,
          onError
        )

        const subscription = annotationService.reload().subscribe((data) => {
          if (data.isReload) {
            let currentVId = versionId

            if (data.versionId) {
              currentVId = data.versionId
            }

            const onNextSup = (response) => {
              if (response.success) {
                setAnnotations(response.data)
              } else {
                toastCenter.message(
                  'Failed',
                  response.message,
                  toastMessageType.WARNING
                )
              }
            }

            getAllAnnotationApi.subscribe(
              orderId,
              attachmentId,
              currentVId,
              onNextSup,
              onComplete,
              onError
            )
          }
        })

        return () => {
          getAllAnnotationApi.unsubscribe()
          subscription.unsubscribe()
        }
      }
    },
    [attachmentId, clientId, getStartingScreenPublic, orderId]
  )

  // load version
  const loadVersions = useCallback(
    (versionIndex, versionId = null) => {
      const attachmentVersionApi = new AttachmentVersionAPI()

      const onNext = (response) => {
        if (response.success) {
          const versionOrdered = response.data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt)
          })

          let selectedVersion

          // const publicIndex = versionOrdered.findIndex((x) => !x.isInternal)

          // if (publicIndex > -1 && versionIndex === 0) {
          //   const sorted = versionOrdered.sort(function (x, y) {
          //     return toNumber(y.version) === toNumber(x.version)
          //       ? 0
          //       : toNumber(y.version) < toNumber(x.version)
          //       ? -1
          //       : 1
          //   })
          //   selectedVersion = sorted[versionIndex]
          // }

          //[VIEW COMMENT]
          if (versionId) {
            // if came from order detail open comment
            const idx = versionOrdered.findIndex((x) => x.id === versionId)
            if (idx > -1) {
              selectedVersion = versionOrdered[idx]
              setVersionIndex(idx)
            }
          } else {
            const publicIndex = versionOrdered.findIndex((x) => !x.isInternal)
            if (
              publicIndex > -1 &&
              versionIndex === 0 &&
              publicQuery === null
            ) {
              selectedVersion = versionOrdered[publicIndex]
            } else {
              const privateIndex = versionOrdered.findIndex((x) => x.isInternal)
              if (
                privateIndex > -1 &&
                versionIndex === 0 &&
                publicQuery === null
              ) {
                selectedVersion = versionOrdered[privateIndex]
              }
            }
          }

          setVersions(versionOrdered)
          loadAnnotation(selectedVersion)
        } else {
          toastCenter.message(
            'Failed',
            response.message,
            toastMessageType.WARNING
          )
        }
      }
      const onComplete = () => {}
      const onError = () => {
        setIsLoading(false)
        toastCenter.messageServerError()
      }

      attachmentVersionApi.subscribe(
        orderId,
        attachmentId,
        onNext,
        onComplete,
        onError
      )

      return attachmentVersionApi
    },
    [attachmentId, loadAnnotation, orderId, publicQuery]
  )

  useEffect(() => {
    if (isFileExist) {
      const subscription = loadVersions(0, queryVersionId)

      return () => {
        subscription.unsubscribe()
      }
    }

    return () => {}
  }, [isFileExist, loadVersions, queryVersionId])

  useEffect(() => {
    if (isFileExist) {
      const subscription = attachmentVersionService
        .reload()
        .subscribe((val) => {
          if (val) {
            loadVersions(versionIndex)
          }
        })

      return () => {
        subscription.unsubscribe()
      }
    }

    return () => {}
  }, [isFileExist, loadVersions, versionIndex])
  //#endregion

  //#region Functions
  const handleChangeVersion = useCallback(
    (versionId, isTabPublicSelected) => {
      // loading
      setIsLoadAnnotation(true)

      const getAllAnnotationApi = new GetAllAnnotationAPI()
      const onNext = (response) => {
        if (response.success) {
          setAnnotations(response.data)

          if (versions.length > 0) {
            let idx = versions.findIndex((x) => x.id === versionId)
            setVersionIndex(idx)

            if (isTabPublicSelected) {
              const filteredVersions = versions.filter((x) => !x.isInternal)
              idx = filteredVersions.findIndex((x) => x.id === versionId)

              if (idx > -1) {
                setIsPreview(filteredVersions[idx].isDisabledAnnotation)
              }
            } else {
              if (clientId === clientLogin.ADMIN) {
                if (versions[idx].isInternal) {
                  setIsPreview(versions[idx].isDisabledAnnotation)
                } else {
                  setIsPreview(versions[idx].isDisabledInternalAnnotation)
                }
              }
            }
          }
        } else {
          toastCenter.message(
            'Failed',
            response.message,
            toastMessageType.WARNING
          )
        }
      }

      const onComplete = () => {
        setIsLoadAnnotation(false)
      }

      const onError = () => {
        setIsLoadAnnotation(false)
        toastCenter.messageServerError()
      }

      getAllAnnotationApi.subscribe(
        orderId,
        attachmentId,
        versionId,
        onNext,
        onComplete,
        onError
      )
    },
    [attachmentId, clientId, orderId, versions]
  )

  const handleSendComment = useCallback(
    (
      versionId,
      message,
      mentions = [],
      topicId = null,
      annotation = '',
      isTopic = true,
      pdfAnnotationModule = null
    ) => {
      if (isTopic) {
        // create new topic
        const createTopicApi = new CreateTopicAPI()

        const onNext = (response) => {
          if (response.success) {
            createTopicService.emitReload({
              id: response.id,
              user: {
                name: `${user.firstname} ${user.lastname}`,
                avatar: user.avatar,
              },
              message: message,
              annotation: annotation,
              dateCreated: fromZonedTime(
                new Date(),
                Intl.DateTimeFormat().resolvedOptions().timeZone
              ).toISOString(),
            })
          } else {
            toastCenter.message(
              'Failed',
              response.message,
              toastMessageType.WARNING
            )
          }
        }

        const onComplete = () => {
          annotationService.emitReload({ isReload: true, versionId: versionId })
        }

        const onError = () => {
          toastCenter.messageServerError()
        }

        let data = new FormHandler()
        data.append('message', message)
        data.append('mentions', JSON.stringify(mentions))
        data.append('annotation', annotation)
        if (pdfAnnotationModule)
          data.append('pdfAnnotationModule', pdfAnnotationModule)

        createTopicApi.subscribe(
          orderId,
          attachmentId,
          versionId,
          data.all(),
          onNext,
          onComplete,
          onError
        )
      } else {
        // comment topic
        const onNext = (response) => {
          if (response.success) {
            commentTopicService.emitReload({
              topicId: topicId,
              id: response.id,
              user: {
                name: `${user.firstname} ${user.lastname}`,
                avatar: user.avatar,
              },
              message: message,
              annotation: annotation,
              dateCreated: fromZonedTime(
                new Date(),
                Intl.DateTimeFormat().resolvedOptions().timeZone
              ).toISOString(),
            })
          } else {
            toastCenter.message(
              'Failed',
              response.message,
              toastMessageType.WARNING
            )
          }
        }

        const onComplete = () => {
          annotationService.emitReload({ isReload: true, versionId: versionId })
        }

        const onError = () => {
          toastCenter.messageServerError()
        }

        let data = new FormHandler()
        data.append('message', message)
        data.append('mentions', JSON.stringify(mentions))

        const addCommentApi = new AddCommentAPI()
        addCommentApi.subscribe(
          orderId,
          attachmentId,
          versionId,
          topicId,
          data.all(),
          onNext,
          onComplete,
          onError
        )
      }
    },
    [attachmentId, orderId, user]
  )

  const handleSilentUpdate = useCallback(
    (
      versionId,
      message,
      mentions = [],
      topicId = null,
      annotation = '',
      isTopic = true,
      pdfAnnotationModule = null
    ) => {
      if (isTopic) {
        // create new topic
        const createTopicSilentlyApi = new CreateTopicSilentlyAPI()

        const onNext = (response) => {
          if (response.success) {
            createTopicService.emitReload({
              id: response.id,
              user: {
                name: `${user.firstname} ${user.lastname}`,
                avatar: user.avatar,
              },
              message: message,
              annotation: annotation,
              dateCreated: fromZonedTime(
                new Date(),
                Intl.DateTimeFormat().resolvedOptions().timeZone
              ).toISOString(),
            })
          } else {
            toastCenter.message(
              'Failed',
              response.message,
              toastMessageType.WARNING
            )
          }
        }

        const onComplete = () => {}

        const onError = () => {
          toastCenter.messageServerError()
        }

        let data = new FormHandler()
        data.append('message', message)
        data.append('mentions', JSON.stringify(mentions))
        data.append('annotation', annotation)
        if (pdfAnnotationModule)
          data.append('pdfAnnotationModule', pdfAnnotationModule)

        createTopicSilentlyApi.subscribe(
          orderId,
          attachmentId,
          versionId,
          data.all(),
          onNext,
          onComplete,
          onError
        )
      }
    },
    [attachmentId, orderId, user]
  )

  const handleSaveEditComment = useCallback(
    (versionId, commentId, message, mentions = [], isTopic = true) => {
      // const orderId = match.params.orderId;
      // const attachmentId = match.params.attachmentId;

      if (isTopic) {
        // update message topic
        const onNext = (response) => {
          if (response.success) {
            editTopicService.emitReload({
              id: commentId,
              message: message,
              dateCreated: fromZonedTime(
                new Date(),
                Intl.DateTimeFormat().resolvedOptions().timeZone
              ).toISOString(),
            })
          } else {
            toastCenter.message(
              'Failed',
              response.message,
              toastMessageType.WARNING
            )
          }
        }

        const onComplete = () => {
          annotationService.emitReload({ isReload: true, versionId: versionId })
        }

        const onError = () => {
          toastCenter.messageServerError()
        }

        let data = new FormHandler()
        data.append('data', message)
        data.append('mentions', JSON.stringify(mentions))

        const editAnnotationAPI = new EditAnnotationAPI()
        editAnnotationAPI.subscribe(
          orderId,
          attachmentId,
          versionId,
          commentId,
          typeAnnotationEdit.MESSAGE,
          data.all(),
          onNext,
          onComplete,
          onError
        )
      } else {
        // update message comment
        const onNext = (response) => {
          if (response.success) {
            editCommentService.emitReload({
              id: commentId,
              message: message,
              dateCreated: fromZonedTime(
                new Date(),
                Intl.DateTimeFormat().resolvedOptions().timeZone
              ).toISOString(),
            })
          } else {
            toastCenter.message(
              'Failed',
              response.message,
              toastMessageType.WARNING
            )
          }
        }

        const onComplete = () => {
          annotationService.emitReload({ isReload: true, versionId: versionId })
        }

        const onError = () => {
          toastCenter.messageServerError()
        }

        let data = new FormHandler()
        data.append('data', message)
        data.append('mentions', JSON.stringify(mentions))

        const editCommentApi = new EditCommentAPI()
        editCommentApi.subscribe(
          orderId,
          attachmentId,
          versionId,
          commentId,
          data.all(),
          onNext,
          onComplete,
          onError
        )
      }
    },
    [attachmentId, orderId]
  )

  const handleDeleteComment = useCallback(
    (versionId, commentId, isTopic = false) => {
      // const orderId = match.params.orderId;
      // const attachmentId = match.params.attachmentId;

      if (isTopic) {
        // delete topic
        const onNext = (response) => {
          if (response.success) {
            deleteTopicService.emitReload({
              id: commentId,
            })
          } else {
            toastCenter.message(
              'Failed',
              response.message,
              toastMessageType.WARNING
            )
          }
        }

        const onComplete = () => {
          annotationService.emitReload({ isReload: true, versionId: versionId })
        }

        const onError = () => {
          toastCenter.messageServerError()
        }

        const deleteTopicApi = new DeleteTopicAPI()
        deleteTopicApi.subscribe(
          orderId,
          attachmentId,
          versionId,
          commentId,
          onNext,
          onComplete,
          onError
        )
      } else {
        // delete comment
        const onNext = (response) => {
          if (response.success) {
            deleteCommentService.emitReload({
              id: commentId,
            })
          } else {
            toastCenter.message(
              'Failed',
              response.message,
              toastMessageType.WARNING
            )
          }
        }

        const onComplete = () => {
          annotationService.emitReload({ isReload: true, versionId: versionId })
        }

        const onError = () => {
          toastCenter.messageServerError()
        }

        const deleteCommentApi = new DeleteCommentAPI()
        deleteCommentApi.subscribe(
          orderId,
          attachmentId,
          versionId,
          commentId,
          onNext,
          onComplete,
          onError
        )
      }
    },
    [attachmentId, orderId]
  )

  const handleResolveComment = useCallback(
    (versionId, topicId) => {
      // const orderId = match.params.orderId;
      // const attachmentId = match.params.attachmentId;

      // resolve topic
      const onNext = (response) => {
        if (response.success) {
          annotationResolveService.emitReload({
            topicId: topicId,
            isResolve: true,
          })
        } else {
          toastCenter.message(
            'Failed',
            response.message,
            toastMessageType.WARNING
          )
        }
      }

      const onComplete = () => {
        annotationService.emitReload({ isReload: true, versionId: versionId })
      }

      const onError = () => {
        toastCenter.messageServerError()
      }

      let data = new FormHandler()
      data.append('data', true)

      const editAnnotationAPI = new EditAnnotationAPI()
      editAnnotationAPI.subscribe(
        orderId,
        attachmentId,
        versionId,
        topicId,
        typeAnnotationEdit.RESOLVE,
        data.all(),
        onNext,
        onComplete,
        onError
      )
    },
    [attachmentId, orderId]
  )

  const handleReopenComment = useCallback(
    (versionId, topicId) => {
      // const orderId = match.params.orderId;
      // const attachmentId = match.params.attachmentId;

      // reopen topic
      const onNext = (response) => {
        if (response.success) {
          annotationResolveService.emitReload({
            topicId: topicId,
            isResolve: false,
          })
        } else {
          toastCenter.message(
            'Failed',
            response.message,
            toastMessageType.WARNING
          )
        }
      }

      const onComplete = () => {
        annotationService.emitReload({ isReload: true, versionId: versionId })
      }

      const onError = () => {
        toastCenter.messageServerError()
      }

      let data = new FormHandler()
      data.append('data', false)

      const editAnnotationAPI = new EditAnnotationAPI()
      editAnnotationAPI.subscribe(
        orderId,
        attachmentId,
        versionId,
        topicId,
        typeAnnotationEdit.RESOLVE,
        data.all(),
        onNext,
        onComplete,
        onError
      )
    },
    [attachmentId, orderId]
  )

  const updateOrderStatus = useCallback(
    (id, status, callback = null, textReason = '') => {
      let data = new FormHandler()
      data.append('moveto', status)

      if (status === 'on-hold') {
        data.append('reason', textReason)
      }

      let orderDetailEditProgressAPI = new OrderDetailEditProgressAPI()
      const onNext = (response) => {
        if (!response.success) {
          toastCenter.message(
            'Failed!',
            response.message,
            toastMessageType.WARNING
          )
        } else {
          if (typeof callback === 'function') {
            callback()
          }

          designOrderService.emitReload(true)
        }
      }

      const onComplete = () => {}

      const onError = () => {
        toastCenter.messageServerError()
      }

      orderDetailEditProgressAPI.subscribe(
        id,
        data.all(),
        onNext,
        onComplete,
        onError
      )
    },
    []
  )

  const handleActionMakeChange = useCallback(() => {
    // const orderId = match.params.orderId;
    updateOrderStatus(orderId, 'in-progress', () => {
      navigate('/')
    })
  }, [navigate, orderId, updateOrderStatus])

  const handleActionStayInReview = useCallback(() => {
    navigate('/')
  }, [navigate])

  const handleActionPutOnHold = useCallback(() => {
    // const orderId = match.params.orderId;

    onPromptReasonShow(
      'Please provide a reason for holding the order',
      (textReason) => {
        updateOrderStatus(
          orderId,
          'on-hold',
          () => {
            navigate('/')
          },
          textReason
        )
        onPromptReasonHide()
      },
      () => onPromptReasonHide()
    )
  }, [
    navigate,
    onPromptReasonHide,
    onPromptReasonShow,
    orderId,
    updateOrderStatus,
  ])

  const handleActionOrderApproved = useCallback(() => {
    // const orderId = match.params.orderId;
    updateOrderStatus(orderId, 'approved', () => {
      navigate('/')
    })
  }, [navigate, orderId, updateOrderStatus])

  // creative manager
  const handleApproveCreativeManager = useCallback(
    (e, versionId) => {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()

      const creativeManagerApproveAttachmentApi =
        new CreativeManagerApproveAttachmentAPI()

      const onNext = (response) => {
        if (!response.success) {
          toastCenter.messageServerError()
        } else {
          toastCenter.message('Success', 'The attachment file approved')
          // annotationService.emitReload({ isReload: true, versionId: versionId })
        }
      }

      const onComplete = () => {}

      const onError = () => {
        toastCenter.messageServerError()
      }

      creativeManagerApproveAttachmentApi.subscribe(
        orderId,
        attachmentId,
        versionId,
        onNext,
        onComplete,
        onError
      )
    },
    [attachmentId, orderId]
  )

  const handleReviseCreativeManager = useCallback(
    (e, versionId) => {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()

      const creativeManagerReviseAttachmentApi =
        new CreativeManagerReviseAttachmentAPI()

      const onNext = (response) => {
        if (!response.success) {
          toastCenter.messageServerError()
        } else {
          toastCenter.message('Success', 'The attachment file revised')
          // annotationService.emitReload({ isReload: true, versionId: versionId })
        }
      }

      const onComplete = () => {}

      const onError = () => {
        toastCenter.messageServerError()
      }

      creativeManagerReviseAttachmentApi.subscribe(
        orderId,
        attachmentId,
        versionId,
        onNext,
        onComplete,
        onError
      )
    },
    [attachmentId, orderId]
  )

  const handleDeleteVersion = useCallback(
    (e, version) => {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      onPromptReasonShow(
        'Please provide a reason for this version',
        (textReason) => {
          const deleteAttachmentVersionAPI = new DeleteAttachmentVersionAPI()

          const onNext = (response) => {
            if (!response.success) {
              toastCenter.messageServerError()
            } else {
              toastCenter.message('Success', 'The version already removed')
              onPromptReasonHide()
            }
          }

          const onComplete = () => {}

          const onError = () => {
            toastCenter.messageServerError()
            onPromptReasonHide()
          }

          deleteAttachmentVersionAPI.subscribe(
            orderId,
            attachmentId,
            version.id,
            textReason,
            onNext,
            onComplete,
            onError
          )
        },
        () => onPromptReasonHide()
      )
    },
    [orderId, attachmentId, onPromptReasonShow, onPromptReasonHide]
  )

  // disabled
  // ONLY APPROVED ORDER ABLE TO DOWNLOAD
  const handleDownloadFile = () => {}
  const handleOpenNewTab = () => {}
  const handleCopyFileUrl = () => {}

  const handleClose = () => {
    // const orderId = match.params.orderId;
    navigate(`/orders/detail/${orderId}`)
  }

  const handleReasonChange = useCallback((e) => {
    const charCount = e.target.value.length
    const charLeftTemp = maxLengthReason - charCount
    setCharsLeft(charLeftTemp)
    setStateReason(e.target.value)
    setIsEmptyReason(validator.isEmpty(e.target.value))
  }, [])

  const handleDropContinueReasonPrompt = useCallback(() => {
    if (typeof promptReason.promptCallback.okAction === 'function') {
      promptReason.promptCallback.okAction(stateReason)
    }
    setStateReason('')
    setIsEmptyReason(true)
  }, [promptReason, stateReason])

  const handleDropCancelReasonPrompt = useCallback(() => {
    if (typeof promptReason.promptCallback.cancelAction === 'function') {
      promptReason.promptCallback.cancelAction()
    }
    setStateReason('')
    setIsEmptyReason(true)
  }, [promptReason])
  //#endregion

  return (
    <AbilityContext.Provider value={ability}>
      <Helmet>
        <title>{branding.NAME} - Annotation</title>
      </Helmet>
      <Annotation
        isLoading={isLoading}
        currentUser={user}
        listMentions={listMentions}
        viewComment={{
          queryVersionId: queryVersionId,
          queryCommentId: queryCommentId,
          queryPublic: queryPublic === 'true',
        }}
        data={annotations}
        isLoadAnnotation={isLoadAnnotation}
        listVersion={versions}
        onDownloadFile={handleDownloadFile}
        onOpenNewTab={handleOpenNewTab}
        onCopyFileUrl={handleCopyFileUrl}
        onSendComment={handleSendComment}
        onSilentUpdate={handleSilentUpdate}
        onSaveEditComment={handleSaveEditComment}
        onDeleteComment={handleDeleteComment}
        onResolveComment={handleResolveComment}
        onReopenComment={handleReopenComment}
        onChangeVersion={handleChangeVersion}
        onClose={handleClose}
        onActionMakeChange={handleActionMakeChange}
        onActionStayInReview={handleActionStayInReview}
        onActionPutOnHold={handleActionPutOnHold}
        onActionOrderApproved={handleActionOrderApproved}
        ability={reviewerAbility}
        isPreview={isPreview}
        onApproveVersion={handleApproveCreativeManager}
        onReviseVersion={handleReviseCreativeManager}
        orderProgress={
          orderDetail && orderDetail.progress ? orderDetail.progress : ''
        }
        currentUserRole={user.role}
        isInternalTabDisabled={isInternalTabDisabled}
        isPublicTabDisabled={isPublicTabDisabled}
        attachmentTypeStartFrom={attachmentTypeStartFrom}
        isStartingScreenPublic={getStartingScreenPublic()}
        byteLength={byteLength}
        downloadWithWatermark={downloadWithWatermark}
        onDeleteAttachmentVersion={handleDeleteVersion}
      />

      {isShowAdBlockerWarning ? (
        <AdBlockWarning onHide={() => setIsShowAdBlockerWarning(false)} />
      ) : null}

      <Modal
        show={promptReason.isPromptShow}
        onHideModal={() => false}
        modalSize="modal-md"
        isForceShow
        useClose={false}
        style={{ zIndex: 1160 }}
        styleBackDrop={{ zIndex: 1055 }}
      >
        <div className="d-flex flex-column">
          <div className="d-flex mb-4">
            <Logo width={120} />
          </div>
          <div className="mb-4">
            <Textarea
              label={promptReason.label}
              isRequired
              maxLength={maxLengthReason}
              isEmpty={isEmptyReason}
              value={stateReason}
              onChange={handleReasonChange}
            />
            <div>
              <small className="form-text text-muted">
                Characters left: {charsLeft}
              </small>
            </div>
          </div>
          <div className="d-flex flex-row">
            <button
              type="button"
              className={`btn ${
                isEmptyReason ? 'btn-secondary' : 'btn-darkprimary'
              } mr-3`}
              disabled={isEmptyReason}
              onClick={handleDropContinueReasonPrompt}
            >
              Continue
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleDropCancelReasonPrompt}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      {/* <a
        href="https://acrobat.adobe.com"
        rel="noopener noreferrer"
        target="_blank"
        style={{
          position: 'fixed',
          bottom: '15px',
          left: '15px',
          zIndex: '2000',
        }}
      >
        Powered by Adobe Document Cloud
      </a>*/}
    </AbilityContext.Provider>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    promptReason: state.promptReason,
    userRole: state.auth.role,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiStart: () => dispatch(actions.apiStart()),
    apiStop: () => dispatch(actions.apiStop()),
    onPromptReasonShow: (label, okCallback, cancelCallback) =>
      dispatch(actions.promptReasonShow(label, okCallback, cancelCallback)),
    onPromptReasonHide: () => dispatch(actions.promptReasonHide()),
    routeRemoveRedirect: () => dispatch(actions.routeRemoveRedirect()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AnnotationContainer))
