const Theme = {
  ORANGE: '#f68e56',
  RED: '#EB0045',
  DARKBLUE: '#60269E',
  BLUE: '#6FCFEB',
  BLACK: '#343a3f',
  DARK: '#4d5256',
}

export default Theme
