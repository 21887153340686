import React, { useState, useEffect } from 'react'
import { AnnouncementAPI } from '../../api/Commons/GetAnnouncements'
import * as Icon from 'react-feather'

const Announcements = ({ onClose = () => {} }) => {
  const [notifications, setNotifications] = useState([])

  const renderMessages = (messages) => {
    return {
      __html: messages,
    }
  }

  useEffect(() => {
    const announcementsAPI = new AnnouncementAPI()

    const onNext = (response) => {
      if (response.success) {
        const transformData = response.data.map((d) => ({
          ...d,
          showCloseButton: true,
        }))
        setNotifications(transformData)
      }
    }
    const onComplete = () => {}

    announcementsAPI.subscribe(onNext, onComplete, () => {})

    return () => {
      announcementsAPI.unsubscribe()
      setNotifications([])
    }
  }, [])

  const hideCloseButton = (idx) => {
    const updateCloseBtn = notifications.reduce((prev, current, currentIdx) => {
      if (currentIdx === idx) {
        current.showCloseButton = false
      }

      return prev.push(current)
    }, [])

    setNotifications(updateCloseBtn)
    onClose()
  }

  return notifications.length
    ? notifications.map((announcement, idx) => (
        <div key={idx} className="announcement-content">
          <div
            className="flash-notification"
            style={{
              backgroundColor: announcement.styles.backgroundColor,
              color: announcement.styles.textColor,
            }}
            dangerouslySetInnerHTML={renderMessages(announcement.html)}
          />
          {announcement.showCloseButton && (
            <button
              className="close-btn"
              onClick={() => hideCloseButton(idx)}
              style={{ color: announcement.styles.textColor }}
            >
              <Icon.XCircle />
            </button>
          )}
        </div>
      ))
    : null
}

export default Announcements
