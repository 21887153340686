import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Icon from 'react-feather'

import './Header.scss'
import NavBar from './NavBar/NavBar'
import Button from '../../components/Button/Button'
import * as Router from '../../shared/router'
import { getCurrentView } from '../../shared/utility'

const Header = ({ isFullScreen, onToggle, user }) => {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <div className="header-wrapper">
      <header className="header-toolbar">
        <NavBar isFullScreen={isFullScreen} onToggle={onToggle} />
      </header>
      {user &&
        getCurrentView(user) !== 'client' &&
        location &&
        (location.pathname === Router.home ||
          location.pathname === Router.orderListView) && (
          <div className="mt-4 d-flex flex-column w-100">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="d-flex">
                <h2>
                  {location && location.pathname === Router.orderListView
                    ? `List View`
                    : 'Board View'}
                </h2>
              </div>
              <div className="d-flex flex-end">
                <Button
                  className={`btn d-flex align-items-center justify-content-center ${
                    location && location.pathname === Router.orderListView
                      ? `btn-outlined`
                      : 'btn-darkprimary'
                  } mr-2`}
                  onClick={() => {
                    navigate(Router.home)
                  }}
                  title="Show Board View"
                >
                  Board View <Icon.Grid className="feather" />
                </Button>
                <Button
                  className={`btn d-flex align-items-center justify-content-center ${
                    location && location.pathname === Router.orderListView
                      ? `btn-darkprimary`
                      : 'btn-outlined'
                  }`}
                  onClick={() => {
                    navigate(Router.orderListView)
                  }}
                  title="Show List View"
                >
                  List View <Icon.List className="feather" />
                </Button>
              </div>
            </div>
          </div>
        )}
    </div>
  )
}

Header.propTypes = {
  isFullScreen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default Header
