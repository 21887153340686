import React, { Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'

import * as Router from '../../../shared/router'
import { getCurrentView } from '../../../shared/utility'
import Spinner from '../../../components/Loader/Spinner/Spinner'
import DragAndDrop from '../../../components/DragAndDrop/DragAndDrop'

const CreateOrderContainer = lazy(
  () => import('../../CreateOrder/CreateOrderContainer')
)
const CloneOrderContainer = lazy(
  () => import('../../CreateOrder/CloneOrderContainer')
)
const TeamMember = lazy(() => import('../../TeamMember/TeamMember'))
const DesignOrderBoard = lazy(() => import('../../KanbanOrder/KanbanOrder'))
const ClientBoard = lazy(() => import('../../ClientBoard/ClientBoard'))
const GeneralSetting = lazy(() => import('../../GeneralSetting/GeneralSetting'))
const ManageProfile = lazy(() => import('../../ManageProfile/ManageProfile'))
const PaygCustomerInfo = lazy(
  () => import('../../PaygCustomerInfo/PaygCustomerInfo')
)
const PaymentMethod = lazy(() => import('../../PaymentMethod/PaymentMethod'))
const ManageInvoice = lazy(() => import('../../ManageInvoice/ManageInvoice'))
const MyCart = lazy(() => import('../../MyCart/MyCart'))
const Checkout = lazy(() => import('../../Checkout/Checkout'))
const ManagePackage = lazy(() => import('../../ManagePackage/ManagePackage'))
const CheckoutInvoice = lazy(() => import('../../Checkout/CheckoutInvoice'))
const ArchivedOrder = lazy(() => import('../../ArchivedOrder/ArchivedOrder'))
const MyImages = lazy(() => import('../../MyImages/MyImages'))
const Retail = lazy(() => import('../../ManageRetail/ManageRetail'))
const Payg = lazy(() => import('../../ManagePayg/ManagePayg'))
const CartCheckout = lazy(() => import('../../Checkout/CheckoutProcess'))
const OldDesignOrderBoard = lazy(
  () => import('../../DesignOrderBoard/DesignOrderBoard')
)
const OrderListView = lazy(() => import('../../ListViewOrder/ListViewOrder'))
const WorkflowAndRole = lazy(
  () => import('../../WorkflowAndRole/WorkflowAndRole')
)
function Main({ user }) {
  const currentView = getCurrentView(user)
  const HomeComponent =
    currentView === 'client' ? ClientBoard : DesignOrderBoard

  return (
    <>
      <DragAndDrop>
        <main className="main">
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path={Router.managePackage} element={<ManagePackage />} />
              <Route path={Router.cart} element={<MyCart />} />
              <Route path={Router.cartCheckout} element={<CartCheckout />} />
              <Route path={Router.checkoutProcess} element={<Checkout />} />
              <Route
                path={Router.checkoutProcessInvoice}
                element={<CheckoutInvoice />}
              />
              <Route path={Router.paymentMethod} element={<PaymentMethod />} />
              <Route
                path={Router.editPaymentMethod}
                element={<PaymentMethod />}
              />
              <Route path={Router.invoices} element={<ManageInvoice />} />
              <Route path={Router.invoiceDetail} element={<ManageInvoice />} />
              <Route path={Router.teamMember} element={<TeamMember />} />
              <Route path={Router.editTeamMember} element={<TeamMember />} />
              <Route
                path={Router.generalSetting}
                element={<GeneralSetting />}
              />
              <Route path={Router.manageProfile} element={<ManageProfile />} />
              <Route
                path={Router.paygCustomerInfo}
                element={<PaygCustomerInfo />}
              />
              <Route path={Router.orderDetail} element={<DesignOrderBoard />} />
              <Route
                path={Router.createOrder}
                element={<CreateOrderContainer />}
              />
              <Route
                path={Router.cloneOrder}
                element={<CloneOrderContainer />}
              />
              <Route path={Router.home} element={<HomeComponent />} />
              <Route path={Router.createCustomer} element={<ClientBoard />} />
              <Route path={Router.customerDetail} element={<ClientBoard />} />
              <Route path={Router.archivedOrder} element={<ArchivedOrder />} />
              <Route path={Router.myImages} element={<MyImages />} />
              <Route path={Router.retail} element={<Retail />} />
              <Route path={Router.payg} element={<Payg />} />
              <Route
                path={Router.workflowAndRoles}
                element={<WorkflowAndRole />}
              />
              <Route
                path={Router.oldKanban}
                element={<OldDesignOrderBoard />}
              />
              <Route path={Router.orderListView} element={<OrderListView />} />
              <Route path="*" element={<Navigate to={Router.home} replace />} />
            </Routes>
          </Suspense>
        </main>
      </DragAndDrop>
    </>
  )
}

const mapStateToProps = ({ user }) => ({
  user,
})

export default connect(mapStateToProps)(Main)
