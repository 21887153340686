import React, { useState, useEffect, useContext } from 'react'
import Joyride, { STATUS } from 'react-joyride'
import { AbilityContext } from '../../context/abilityContext'
import { abilityAction, abilityComponent } from '../../shared/ability'
import { tourGuideAction } from '../../shared/tourGuideAction'
import { userDetailService } from '../../services/userDetailService/userDetailService'
import clientLogin from '../../shared/clientLogin'

const initSteps = [
  {
    target: '.welcomeTourGuide',
    title: 'Welcome to the ngine Tour Guide',
    content:
      'Please follow this tour guide to understand ngine’s essential features. To end or skip through the tour guide, click the Skip button. You can also restart this tour guide at anytime by clicking on Restart Tour Guide button in the General Settings page.',
    disableBeacon: true,
    type: 'hover',
    isFixed: true,
    spotlightPadding: 0,
    styles: {
      spotlight: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },
    floaterProps: {
      hideArrow: true,
    },
  },
  {
    target: '.top-link-user',
    title: 'Account Menu',
    content:
      'Click on the Account Menu icon to show the list of account features. Here, you can manage your Profile, Invoices and Payment Methods, Team Members and General Settings.',
    disableBeacon: true,
    type: 'hover',
    isFixed: true,
    spotlightPadding: 0,
    placement: 'left-start',
  },
  {
    target: '.notification-nav',
    title: 'Notifications',
    content:
      'Click on the Notifications icon to show the list of notifications you have received. The system will notify you for every Account, Order, Subscription and Purchase related event.',
    disableBeacon: true,
    type: 'hover',
    isFixed: true,
    spotlightPadding: 0,
    placement: 'left-start',
  },
  {
    target: '.user-component-detail',
    title: 'Infobar',
    content:
      "The Infobar shows information regarding your Account. It displays information relating to the subscription type, payment due date, the amount and usage of Allocated Hours, Active Orders, Orders Quantity, Storage Capacity, ngine's business hours and your current local time zone.",
    disableBeacon: true,
    type: 'hover',
    isFixed: true,
    placement: 'right-start',
  },
  {
    target: '.btn-plus-tooltip',
    title: 'Subscription detail button',
    content:
      'This button will navigate you to the Package Detail screen, which will show you the specification of your subscription.',
    disableBeacon: true,
    type: 'hover',
    isFixed: true,
    spotlightPadding: 2,
    styles: {
      spotlight: {
        border: '2px solid #FFA500',
      },
    },
    placement: 'right-start',
  },
  {
    target: '.li-create-order',
    title: 'Create New Order button',
    content:
      'This button will navigate you to the Order Creation screen, this shows you the list of services to select as the basis of the order. You will need to fill in the New Order form with information such as Order Title, Print Specifications, Order Service Requirement, Order Colour Specification, Client’s Industry Sector, Client’s Overview, Client’s Unique Selling Proposition, Order Description, Order’s Due Date, File Attachments and any possible Collaborators.',
    disableBeacon: true,
    type: 'hover',
    isFixed: true,
    spotlightPadding: 0,
    styles: {
      spotlight: {
        border: '1px solid #60269E',
      },
    },
  },
  {
    target: '.main',
    title: 'Order Board',
    content:
      'The Order Board is based on a Kanban board which shows information regarding your Order status and progress. The boards columns represent status which are New Order, In Progress, In Review, On Hold, Approved, and Cancelled. ',
    disableBeacon: true,
    lastStep: true,
    type: 'hover',
    isFixed: true,
    spotlightPadding: 0,
    placement: 'top-start',
  },
  {
    target: '.quick-search-wrapp',
    title: 'Quick Search',
    content:
      'Is a single search interface that returns results from multiple orders based on the keyword you enter.',
    disableBeacon: true,
    lastStep: true,
    type: 'hover',
    isFixed: true,
    spotlightPadding: 0,
    placement: 'right-start',
    styles: {
      spotlight: {
        border: '1px solid #FFA500',
      },
    },
  },
]

const DashboardTourGuide = ({ match, run }) => {
  const [autoRun, setAutoRun] = useState(run)
  const [steps, setSteps] = useState([])
  const abilityContext = useContext(AbilityContext)

  const customStyles = {
    overlay: {
      position: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    buttonClose: {
      display: 'none',
    },
    floater: {
      position: 'fixed',
    },
  }

  const handleJoyrideCallback = (data) => {
    const { status, lifecycle } = data

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) &&
      lifecycle === 'complete'
    ) {
      tourGuideAction('dashboard', 'false', () => {
        userDetailService.emitReload(true)
      })
    }
  }

  useEffect(() => {
    if (match && match.path === '/') {
      setAutoRun(run)
    }
  }, [run, match])

  useEffect(() => {
    const clientId = localStorage.getItem('clientId')
    if (clientId === null) return false

    if (clientId === clientLogin.ADMIN) {
      setSteps([
        initSteps[0],
        initSteps[1],
        initSteps[2],
        {
          target: '.user-component-detail',
          title: 'Infobar',
          content:
            "The Infobar shows information regarding your local time zone and ngine's business hours.",
          disableBeacon: true,
          type: 'hover',
          isFixed: true,
          placement: 'right-start',
        },
        initSteps[6],
        initSteps[7],
      ])
    } else {
      if (
        abilityContext.can(
          abilityAction.READ,
          abilityComponent.NAV_MANAGE_MEMBERSHIP
        )
      ) {
        setSteps(initSteps)
      } else {
        setSteps([
          initSteps[0],
          initSteps[1],
          initSteps[2],
          initSteps[4],
          initSteps[5],
        ])
      }
    }

    if (match && match.path !== '/') {
      setAutoRun(false)
    }
  }, [match, abilityContext])

  return (
    <div className="tour-guide-home">
      <Joyride
        callback={handleJoyrideCallback}
        run={autoRun}
        steps={steps}
        styles={customStyles}
        floaterProps={{ disableAnimation: true }}
        showSkipButton={true}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          skip: 'Skip',
        }}
        continuous={true}
      />
    </div>
  )
}

export default (DashboardTourGuide)
