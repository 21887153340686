import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// const RNDContext = createDndContext(HTML5Backend)

function useDNDProviderElement(props) {
  // const manager = useRef(RNDContext)

  if (!props.children) return null

  return <DndProvider backend={HTML5Backend}>{props.children}</DndProvider>
}

function DragAndDrop(props) {
  const DNDElement = useDNDProviderElement(props)
  return <React.Fragment>{DNDElement}</React.Fragment>
}

export default DragAndDrop
