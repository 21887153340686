import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import tippy from 'tippy.js'

// import MembershipButtonPopover from '../MembershipButtonPopover/MembershipButtonPopover'
import ProgressBar from '../../ProgressBar/ProgressBar'
// import * as Router from '../../../shared/router'
// import { Can } from '../../../context/abilityContext'
// import { abilityAction, abilityComponent } from '../../../shared/ability'
// import { getCurrentView } from '../../../shared/utility'
import { isFloat } from '../../../shared/utility'

const MembershipOrder = ({ user, isLoading, style, showGear = true }) => {
  const containerRef = useRef()
  const [extraRetail, setExtraRetail] = useState(0)
  const [subscriptionRetail, setSubscriptionRetail] = useState(0)
  const [customTippy, setCustomTippy] = useState()

  useEffect(() => {
    const totalOrderPopup = document.getElementById('total-order-popup')
    if (customTippy)
      customTippy.setProps({
        content: totalOrderPopup.innerHTML,
        animation: 'shift-away',
        zIndex: 1020,
        appendTo: containerRef.current,
        allowHTML: true,
      })
  }, [customTippy])

  useEffect(() => {
    if (user !== null) {
      let totalExtra = 0,
        totalSubscription = 0

      totalExtra += parseInt(user.retail && user.retail.totalOrderExtra)
      totalSubscription += parseInt(
        user.retail ? user.retail.totalOrderSubscription : 0
      )
      if (totalExtra > 0) setExtraRetail(totalExtra)
      if (totalSubscription > 0) setSubscriptionRetail(totalSubscription)
    }

    const totalOrderPopup = document.getElementById('total-order-popup')
    const customTippy = tippy(containerRef.current, {
      content: totalOrderPopup.innerHTML,
      animation: 'shift-away',
      zIndex: 1020,
      appendTo: containerRef.current,
      allowHTML: true,
    })

    setCustomTippy(customTippy)
  }, [user])

  if ((user === null && isLoading) || (user === null && !isLoading)) {
    return null
  }

  const used = user.membership.orders.used
    ? parseInt(user.membership.orders.used)
    : 0
  const space =
    user.membership.orders.quota === '&infin;'
      ? 0
      : parseInt(user.membership.orders.quota) +
        parseInt(user.retail ? user.retail.totalOrderQuota : 0)
  const percentOfUsedTitle =
    user.membership.orders.quota === '&infin;' || user.membership.orders.used
      ? isFloat((used / space) * 100)
        ? `(${((used / space) * 100).toFixed(1)}%)`
        : `(${(used / space) * 100}%)`
      : '(0%)'
  const percentOfUsed =
    user.membership.orders.quota === '&infin;' ? 100 : (used / space) * 100

  // const upgradeHandleClick = () => {
  //   history.push(Router.managePackage)
  // }

  return (
    <div
      className="membership-status"
      ref={containerRef}
      style={Object.assign(style, {})}
    >
      {/* 
        Tippy custom HTML
      */}
      <div id="total-order-popup" style={{ display: 'none' }}>
        <span>
          The total orders available to use in your current subscription.
        </span>
        {(extraRetail > 0 || subscriptionRetail > 0) && (
          <>
            <div>
              <div className="d-flex justify-content-between mt-2">
                <div>{user.membership.title} membership</div>
                <div>{user.membership.orders.quota} orders</div>
              </div>
              {subscriptionRetail > 0 && (
                <div className="d-flex justify-content-between mt-1 text-subscription-retail">
                  <div>Subscription Total Order</div>
                  <div>{user.retail.totalOrderSubscription} orders</div>
                </div>
              )}
              {extraRetail > 0 && (
                <>
                  <div className="d-flex justify-content-between mt-1 text-extra-retail">
                    <div>Extra Total Order</div>
                    <div>{user.retail.totalOrderExtra} orders</div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className="clearfix text-membership-status w-100">
        <div className="float-left d-flex">
          <span className="text-12 text-light">Orders </span>
          {subscriptionRetail > 0 && (
            <span className="dot-subscription-retail"> </span>
          )}
          {extraRetail > 0 && <span className="dot-extra-retail"> </span>}
        </div>
        <div className="float-right clearfix d-flex align-items-center">
          <div
            className={`text-12 d-flex flex-row align-items-center ${
              user.membership.orders.quota !== '&infin;' &&
              parseInt(percentOfUsed) >= 90
                ? 'text-danger'
                : 'text-light'
            }`}
          >
            <strong>{used}</strong>
            <span>&nbsp;/&nbsp;</span>
            {user.membership.orders.quota === '&infin;' ? (
              <span
                dangerouslySetInnerHTML={{ __html: '&infin;' }}
                className="text-18 font-weight-bold line-height-10"
              ></span>
            ) : (
              <strong>{space}</strong>
            )}
            <strong>&nbsp;Orders</strong>
            <span>&nbsp;{percentOfUsedTitle}</span>
          </div>

          {/* {showGear &&
            ((user.isClientWhitelabel && getCurrentView(user) === 'client') ||
              user.isSubscriber) && (
              <Can
                I={abilityAction.READ}
                a={abilityComponent.NAV_MANAGE_MEMBERSHIP}
              >
                <MembershipButtonPopover
                  onClick={upgradeHandleClick}
                  title={'Manage Membership'}
                  content={
                    user && user.membership && user.membership.freeTrial
                      ? 'The page you’re trying to reach will be available once you have passed your free trial'
                      : "Upgrade your monthly membership package to increase your monthly order's quota."
                  }
                  btnText="Manage"
                  style={{ float: 'right' }}
                  isWarningFull={
                    user.membership.orders.quota !== '&infin;' &&
                    parseInt(percentOfUsed) >= 90
                  }
                  isDisabled={
                    user && user.membership && user.membership.freeTrial
                  }
                />
              </Can>
            )} */}
        </div>
      </div>
      <ProgressBar
        valuenow={used}
        valuemin={0}
        valuemax={space}
        isGradient={user.membership.orders.quota === '&infin;'}
      />
    </div>
  )
}

MembershipOrder.propTypes = {
  style: PropTypes.object,
}

export default MembershipOrder
