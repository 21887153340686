import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import validator from 'validator'
import update from 'immutability-helper'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'

import './Auth.scss'
import * as actions from '../../store/actions/index'
import LoginForm from './LoginForm/LoginForm'
import { LoginContext } from '../../context/loginContext'
import clientLogin from '../../shared/clientLogin'
import * as Router from '../../shared/router'
import branding from '../../shared/branding'
import LogoLogin from './Logo.png'
import hero1 from './hero.jpg'
import NgineApiLoader from '../../components/Loader/NgineApiLoader/NgineApiLoader'
let isLoadingTimeOut = null

export const Auth = ({
  onAuth,
  authRedirectPath,
  isAuthenticated,
  clientId,
}) => {
  const defaultValueInput = {
    value: '',
    isEmpty: false,
    isInvalid: false,
  }

  const [email, setEmail] = useState(defaultValueInput)
  const [password, setPassword] = useState(defaultValueInput)
  const [heading, setHeading] = useState('')
  const [attempLogin, setAttempLogin] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setHeading(`Log in`)
    setAttempLogin(0)

    isLoadingTimeOut = setTimeout(() => {
      setIsLoading(false)
    }, 2000)

    return () => clearTimeout(isLoadingTimeOut)
  }, [])

  const handleSubmitLogin = () => {
    let isValidField = true

    // if (!validator.isEmpty(email.value) && !validator.isEmail(email.value)) {
    //   setEmail(
    //     update(email, {
    //       isInvalid: { $set: !validator.isEmail(email.value) },
    //     })
    //   )

    //   isValidField = false
    // }
    if (validator.isEmpty(email.value)) {
      setEmail(
        update(email, {
          isEmpty: { $set: validator.isEmpty(email.value) },
        })
      )

      isValidField = false
    }

    if (validator.isEmpty(password.value)) {
      setPassword(
        update(password, {
          isEmpty: { $set: validator.isEmpty(password.value) },
        })
      )

      isValidField = false
    }

    if (isValidField) {
      onAuth(email.value, password.value, () => {
        setAttempLogin((x) => x + 1)
      })
    }
  }

  let authRedirect = null
  if (isAuthenticated) {
    authRedirect = <Navigate to={authRedirectPath} />
  }

  if (authRedirect === null && clientId && clientId !== clientLogin.CUSTOMER) {
    authRedirect = <Navigate to={Router.signinAdmin} />
  }

  const loginContextValue = {
    email: {
      value: email.value,
      onChange: (e) => {
        setEmail(
          update(email, {
            value: { $set: e.target.value },
            isEmpty: { $set: validator.isEmpty(e.target.value) },
            isInvalid: {
              $set:
                !validator.isEmpty(e.target.value) &&
                !validator.isEmail(e.target.value),
            },
          })
        )
      },
      isEmpty: email.isEmpty,
      isInvalid: email.isInvalid,
    },
    password: {
      value: password.value,
      onChange: (e) => {
        setPassword(
          update(password, {
            value: { $set: e.target.value },
            isEmpty: { $set: validator.isEmpty(e.target.value) },
            isInvalid: {
              $set:
                !validator.isEmpty(e.target.value) &&
                !validator.isLength(e.target.value, { min: 6, max: 20 }),
            },
          })
        )
      },
      isEmpty: password.isEmpty,
      isInvalid: password.isInvalid,
    },
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmitLogin()
    }
  }

  return (
    <div className="Auth h-100">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {authRedirect}
      <Helmet>
        <title>{branding.LOGIN_TITLE}</title>
        <meta
          name="description"
          content="ngine delivers high quality print ready fixes and print ready artwork. get your on demand subscription graphic design services now!"
        />
        <meta
          name="keywords"
          content="login design, buy graphic design, graphic design, graphic design australia, need design, need artwork, subscription design, design service, design platform, need a logo, logo design, business identity, brand identity"
        />
      </Helmet>

      {isLoading ? (
        <NgineApiLoader isLoading={true} />
      ) : (
        <>
          <div className="header">
            <div className="container-fluid container-custom position-relative h-100">
              <div className="row">
                <div className="col-md-6 pr-5 left">
                  <div className="d-flex">
                    <div className="flex-fill logo d-flex align-items-center">
                      {branding.NAME === 'ngine' && (
                        <a
                          href={branding.DOMAIN}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={LogoLogin} className="logo" alt="" />
                        </a>
                      )}
                    </div>

                    {/* {branding.NAME === 'ngine' && (
                      <div className="flex-fill d-flex flex-row justify-content-end align-items-center ml-auto nav-item">
                        <span className="nobreak d-md-inline  d-sm-inline  d-md-inline  d-lg-inline  d-xl-inline">
                          Want an account?
                        </span>
                        <a
                          className="btn btn-lg btn-link btn-register ml-1"
                          href={branding.REGISTER}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Start Free Trial
                        </a>
                      </div>
                    )} */}
                  </div>
                </div>

                <div className="col-6 col-sm-6">&nbsp;</div>
              </div>
            </div>
          </div>

          <div className="form-signin">
            <div className="container-fluid position-relative">
              <div className="row d-flex h-100 align-items-end z-1 position-relative flex-row justify-content-end justify-content-md-start">
                <div className="col-12 order-2 order-sm-1 col-sm-6 section-content">
                  <h1>{heading}</h1>
                  <LoginContext.Provider value={loginContextValue}>
                    <LoginForm onKeyDown={handleKeyDown} />
                  </LoginContext.Provider>

                  <br />
                  <div
                    className={`text-danger text-14 ${
                      attempLogin > 1 ? 'd-none' : 'd-none'
                    }`}
                  >
                    <span>If you've forgotten your password, please click</span>
                  </div>
                  <NavLink to={Router.forgotPassword} className="btn btn-link">
                    Forgot Password?
                  </NavLink>

                  <div className="d-flex flex-row justify-content-left align-items-left">
                    <button
                      type="button"
                      className="btn btn-darkprimary btn-block rounded-pill btn-login"
                      onClick={handleSubmitLogin}
                    >
                      Log in
                    </button>
                  </div>
                </div>

                <div className="col-6 order-1 order-sm-2 col-sm-6 section-img px-0">
                  <img src={hero1} alt="" />
                </div>
              </div>
            </div>
          </div>

          <footer className="footer py-3">
            <div className="container-fluid container-custom">
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="d-flex align-items-center copyright">
                    {branding.NAME === 'ngine' && (
                      <span>
                        Copyright © 2022 <i>ngine</i>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    clientId: state.auth.clientId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password, callback) =>
      dispatch(actions.auth(email, password, false, callback)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
