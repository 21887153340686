import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fromZonedTime } from 'date-fns-tz'
import { Helmet } from 'react-helmet'

import { RequestTokenAPI } from '../../api/GuestReviewer/RequestToken'
import toastCenter from '../../shared/toastCenter'
import { useQuery } from '../../shared/utility'
import { ListAttachmentAPI } from '../../api/GuestReviewer/ListAttachment'
import { AnnotationDetailAPI } from '../../api/GuestReviewer/AnnotationDetail'
import * as actions from '../../store/actions/index'
import Annotation from '../../components/Annotation/Annotation'
import FormHandler from '../../factory/FormHandler'
import { createTopicService } from '../../services/annotationService/createTopicService'
import { commentTopicService } from '../../services/annotationService/commentTopicService'
import { editTopicService } from '../../services/annotationService/editTopicService'
import { editCommentService } from '../../services/annotationService/editCommentService'
import { deleteTopicService } from '../../services/annotationService/deleteTopicService'
import { deleteCommentService } from '../../services/annotationService/deleteCommentService'
import { annotationResolveService } from '../../services/annotationService/annotationResolveService'
import { typeAnnotationEdit } from '../../api/Annotation/EditAnnotation'
import { AddTopicAnnotationAPI } from '../../api/GuestReviewer/AddTopicAnnotation'
import { AddCommentTopicAnnotationAPI } from '../../api/GuestReviewer/AddCommentTopicAnnotation'
import { EditTopicAnnotationAPI } from '../../api/GuestReviewer/EditTopicAnnotation'
import { EditCommentTopicAnnotationAPI } from '../../api/GuestReviewer/EditCommentTopicAnnotation'
import { DeleteCommentTopicAnnotationAPI } from '../../api/GuestReviewer/DeleteCommentTopicAnnotation'
import { DeleteTopicAnnotationAPI } from '../../api/GuestReviewer/DeleteTopicAnnotation'
import BrokenLink from './BrokenLink'
import MessageWelcome from './MessageWelcome'
import { guestReviewerService } from '../../services/guestReviewerService/guestReviewerService'
import { AbilityContext } from '../../context/abilityContext'
import { defineAbilitiesFor } from '../../shared/ability'
import { socketListen, socketRemoveEventListener } from '../../socket/socket'
import socketEvent from '../../socket/socketEvent'
import { attachmentVersionService } from '../../services/attachmentVersionService/attachmentVersionService'
import { annotationService } from '../../services/annotationService/annotationService'
import userRole from '../../shared/userRole'
import { attachmentTypeStartFromStruct } from '../../components/Annotation/shared/constants'

let timeoutToken

const GuestAnnotation = ({ apiStart, apiStop }) => {
  //#region State
  const [accessToken, setAccessToken] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadAnnotation, setIsLoadAnnotation] = useState(true)
  const [orderDetail, setOrderDetail] = useState(null)
  const [annotations, setAnnotations] = useState({ internal: [], public: [] })
  const [versions, setVersions] = useState([])
  // const [listMentions, setListMentions] = useState([]);
  const [versionIndex, setVersionIndex] = useState(0)
  const [guest, setGuest] = useState(null)
  const [isUseNavigation, setIsUseNavigation] = useState(false)
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(0)
  const [isBrokenLink, setIsBrokenLink] = useState(false)
  const [isMessageWelcome, setIsMessageWelcome] = useState(true)
  const [byteLength, setByteLength] = useState(0)
  //#endregion

  let query = useQuery()
  const token = query.get('token')

  // no role
  const ability = defineAbilitiesFor(userRole.GUEST)

  //#region Use Effect
  useEffect(() => {
    const requestTokenApi = new RequestTokenAPI()

    const socketFnVersionDetail = (_) => {
      annotationService.emitReload(true)
    }

    socketListen(socketEvent.RELOAD_VERSION_DETAIL, socketFnVersionDetail)

    const socketFnVersionList = (_) => {
      attachmentVersionService.emitReload(true)
    }

    socketListen(socketEvent.RELOAD_VERSION_LIST, socketFnVersionList)

    if (token) {
      if (timeoutToken) {
        clearTimeout(timeoutToken)
      }

      const onNext = (response) => {
        if (response.success) {
          setAccessToken(response.data.accessToken)
        } else {
          toastCenter.messageServerErrorCustom({
            message: 'Access Denied',
            messages: ['Bad token. You seem to have followed an invalid link'],
          })
          setIsLoading(false)
          setIsLoadAnnotation(false)
          setIsBrokenLink(true)
        }
      }

      const onComplete = () => {}

      const onError = () => {
        toastCenter.messageServerError()
        setIsLoading(false)
        setIsLoadAnnotation(false)
        setIsBrokenLink(true)
      }

      requestTokenApi.subscribe(token, onNext, onComplete, onError)
    } else {
      timeoutToken = setTimeout(() => {
        setIsLoading(false)
        setIsLoadAnnotation(false)
        setIsBrokenLink(true)
      }, 800)
    }

    return () => {
      requestTokenApi.unsubscribe()
      socketRemoveEventListener(
        socketEvent.RELOAD_VERSION_DETAIL,
        socketFnVersionDetail
      )
      socketRemoveEventListener(
        socketEvent.RELOAD_VERSION_LIST,
        socketFnVersionList
      )
    }
  }, [token])

  useEffect(() => {
    const listAttachmentApi = new ListAttachmentAPI()

    if (accessToken !== '') {
      const onNext = (response) => {
        if (response.success && response.data && !response.data.orderIsFrozen) {
          const initData = { ...response.data }
          const initAttachments = [
            ...initData.attachments.sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt)
            }),
          ]
          initData.attachments = initAttachments
          const initialAttachment = initAttachments[0]

          // catch buggy from api if not return version list
          if (
            !initialAttachment.versions ||
            initialAttachment.versions.length === 0
          ) {
            setIsLoading(false)
            setIsLoadAnnotation(false)
            setIsBrokenLink(true)

            return false
          }

          const initVersions = initialAttachment.versions.sort((a, b) => {
            if (a.version < b.version) {
              return -1
            }
            if (a.version > b.version) {
              return 1
            }
            return 0
          })

          setIsUseNavigation(initData.attachments.length > 1)
          setOrderDetail(initData)
          setVersions([initVersions[initVersions.length - 1]])
          setVersionIndex(0)
          setGuest({
            fullname: initData.guestName,
            email: initData.guestEmail,
            id: initData.guestId,
            avatar: initData.guestAvatar,
            message: initData.guestMessage,
            inviter: initData.inviter,
            timeZone: {
              id: '5d0ae335283deb58fa17318f',
              label: '(UTC+08:00) Kuala Lumpur, Singapore',
              utc: 'Asia/Brunei',
            },
          })
        } else {
          toastCenter.messageServerErrorCustom({
            message: 'Access Denied',
            messages: ['Bad token. You seem to have followed an invalid link'],
          })
          setIsBrokenLink(true)
        }
        setIsLoading(false)
        setIsLoadAnnotation(false)
      }
      const onComplete = () => {}
      const onError = () => {
        toastCenter.messageServerError()
        setIsLoading(false)
        setIsLoadAnnotation(false)
        setIsBrokenLink(true)
      }

      listAttachmentApi.subscribe(accessToken, onNext, onComplete, onError)
    }

    return () => {
      listAttachmentApi.unsubscribe()
    }
  }, [accessToken])

  useEffect(() => {
    if (accessToken !== '' && orderDetail !== null) {
      if (
        !orderDetail.attachments[0].versions ||
        orderDetail.attachments[0].versions.length === 0
      ) {
        setIsBrokenLink(true)
        return () => false
      }

      const annotationDetailApi = new AnnotationDetailAPI()

      const onNext = (response) => {
        if (response.success) {
          setAnnotations({ internal: [], public: response.data })
        }
        setIsLoadAnnotation(false)
      }
      const onComplete = () => {}
      const onError = () => {
        toastCenter.messageServerError()
        setIsLoadAnnotation(false)
        setIsBrokenLink(true)
      }

      const orderId = orderDetail.orderId
      const attachmentId = orderDetail.attachments[0].id
      const versionId =
        orderDetail.attachments[0].versions[
          orderDetail.attachments[0].versions.length - 1
        ].id

      annotationDetailApi.subscribe(
        accessToken,
        orderId,
        attachmentId,
        versionId,
        onNext,
        onComplete,
        onError
      )

      const subscription = guestReviewerService.reload().subscribe((data) => {
        if (data.isReload) {
          let selectedAttachmentId = attachmentId
          let selectedVersionId = versionId

          if (data.versionId) {
            selectedVersionId = data.versionId
          }

          if (data.attachmentId) {
            selectedAttachmentId = data.attachmentId
          }

          const onNextSubscription = (response) => {
            if (response.success) {
              setIsLoadAnnotation(false)
              setAnnotations({ internal: [], public: response.data })

              if (data.callback && typeof data.callback === 'function') {
                data.callback()
              }
            }
          }

          annotationDetailApi.subscribe(
            accessToken,
            orderId,
            selectedAttachmentId,
            selectedVersionId,
            onNextSubscription,
            onComplete,
            onError
          )
        }
      })

      return () => {
        annotationDetailApi.unsubscribe()
        subscription.unsubscribe()
      }
    }

    return () => {}
  }, [orderDetail, accessToken])

  useEffect(() => {
    // check file exist at S3
    if (versionIndex > -1 && versions.length > 0) {
      setIsLoading(true)

      const currentVersion = versions[versionIndex]
      setByteLength(parseInt(currentVersion.fileMeta.fileSize, 10))
      setIsLoading(false)

      // if (!currentVersion.fileMeta.isAccessible) {
      //   setIsLoading(false)
      //   setIsLoadAnnotation(false)
      //   setIsBrokenLink(true)
      // }
    }

    return () => {}
  }, [versions, versionIndex])

  useEffect(() => {
    if (isLoading) {
      apiStart()
    } else {
      apiStop()
    }
  }, [isLoading, apiStart, apiStop])
  //#endregion

  //#region Functions
  // annotation action
  const handleSendComment = (
    versionId,
    message,
    mentions = [],
    topicId = null,
    annotation = '',
    isTopic = true
  ) => {
    const orderId = orderDetail.orderId
    const attachmentId = orderDetail.attachments[currentAttachmentIndex].id

    if (isTopic) {
      // create new topic
      const createTopicApi = new AddTopicAnnotationAPI()

      const onNext = (response) => {
        if (response.success) {
          createTopicService.emitReload({
            id: response.id,
            user: {
              name: guest.fullname,
              avatar: guest.avatar,
            },
            message: message,
            annotation: annotation,
            dateCreated: fromZonedTime(
              new Date(),
              Intl.DateTimeFormat().resolvedOptions().timeZone
            ).toISOString(),
          })
        }
      }

      const onComplete = () => {
        guestReviewerService.emitReload({
          isReload: true,
          attachmentId: attachmentId,
          versionId: versionId,
        })
      }

      const onError = () => {
        toastCenter.messageServerError()
      }

      let data = new FormHandler()
      data.append('message', message)
      data.append('mentions', JSON.stringify(mentions))
      data.append('annotation', annotation)

      createTopicApi.subscribe(
        accessToken,
        orderId,
        attachmentId,
        versionId,
        data.all(),
        onNext,
        onComplete,
        onError
      )
    } else {
      // comment topic
      const onNext = (response) => {
        if (response.success) {
          commentTopicService.emitReload({
            topicId: topicId,
            id: response.id,
            user: {
              name: guest.fullname,
              avatar: guest.avatar,
            },
            message: message,
            annotation: annotation,
            dateCreated: fromZonedTime(
              new Date(),
              Intl.DateTimeFormat().resolvedOptions().timeZone
            ).toISOString(),
          })
        }
      }

      const onComplete = () => {
        guestReviewerService.emitReload({
          isReload: true,
          attachmentId: attachmentId,
          versionId: versionId,
        })
      }

      const onError = () => {
        toastCenter.messageServerError()
      }

      let data = new FormHandler()
      data.append('message', message)
      data.append('mentions', JSON.stringify(mentions))

      const addCommentApi = new AddCommentTopicAnnotationAPI()
      addCommentApi.subscribe(
        accessToken,
        orderId,
        attachmentId,
        versionId,
        topicId,
        data.all(),
        onNext,
        onComplete,
        onError
      )
    }
  }

  const handleSaveEditComment = (
    versionId,
    commentId,
    message,
    mentions = [],
    isTopic = true
  ) => {
    const orderId = orderDetail.orderId
    const attachmentId = orderDetail.attachments[currentAttachmentIndex].id

    if (isTopic) {
      // update message topic
      const onNext = (response) => {
        if (response.success) {
          editTopicService.emitReload({
            id: commentId,
            message: message,
            dateCreated: fromZonedTime(
              new Date(),
              Intl.DateTimeFormat().resolvedOptions().timeZone
            ).toISOString(),
          })
        }
      }

      const onComplete = () => {
        guestReviewerService.emitReload({
          isReload: true,
          attachmentId: attachmentId,
          versionId: versionId,
        })
      }

      const onError = () => {
        toastCenter.messageServerError()
      }

      let data = new FormHandler()
      data.append('data', message)
      data.append('mentions', JSON.stringify(mentions))

      const editAnnotationAPI = new EditTopicAnnotationAPI()
      editAnnotationAPI.subscribe(
        accessToken,
        orderId,
        attachmentId,
        versionId,
        commentId,
        typeAnnotationEdit.MESSAGE,
        data.all(),
        onNext,
        onComplete,
        onError
      )
    } else {
      // update message comment
      const onNext = (response) => {
        if (response.success) {
          editCommentService.emitReload({
            id: commentId,
            message: message,
            dateCreated: fromZonedTime(
              new Date(),
              Intl.DateTimeFormat().resolvedOptions().timeZone
            ).toISOString(),
          })
        }
      }

      const onComplete = () => {
        guestReviewerService.emitReload({
          isReload: true,
          attachmentId: attachmentId,
          versionId: versionId,
        })
      }

      const onError = () => {
        toastCenter.messageServerError()
      }

      let data = new FormHandler()
      data.append('data', message)
      data.append('mentions', JSON.stringify(mentions))

      const editCommentApi = new EditCommentTopicAnnotationAPI()
      editCommentApi.subscribe(
        accessToken,
        orderId,
        attachmentId,
        versionId,
        commentId,
        data.all(),
        onNext,
        onComplete,
        onError
      )
    }
  }

  const handleDeleteComment = (versionId, commentId, isTopic = false) => {
    const orderId = orderDetail.orderId
    const attachmentId = orderDetail.attachments[currentAttachmentIndex].id

    if (isTopic) {
      // delete topic
      const onNext = (response) => {
        if (response.success) {
          deleteTopicService.emitReload({
            id: commentId,
          })
        }
      }

      const onComplete = () => {
        guestReviewerService.emitReload({
          isReload: true,
          attachmentId: attachmentId,
          versionId: versionId,
        })
      }

      const onError = () => {
        toastCenter.messageServerError()
      }

      const deleteTopicApi = new DeleteTopicAnnotationAPI()
      deleteTopicApi.subscribe(
        accessToken,
        orderId,
        attachmentId,
        versionId,
        commentId,
        onNext,
        onComplete,
        onError
      )
    } else {
      // delete comment
      const onNext = (response) => {
        if (response.success) {
          deleteCommentService.emitReload({
            id: commentId,
          })
        }
      }

      const onComplete = () => {
        guestReviewerService.emitReload({
          isReload: true,
          attachmentId: attachmentId,
          versionId: versionId,
        })
      }

      const onError = () => {
        toastCenter.messageServerError()
      }

      const deleteCommentApi = new DeleteCommentTopicAnnotationAPI()
      deleteCommentApi.subscribe(
        accessToken,
        orderId,
        attachmentId,
        versionId,
        commentId,
        onNext,
        onComplete,
        onError
      )
    }
  }

  const handleResolveComment = (versionId, topicId) => {
    const orderId = orderDetail.orderId
    const attachmentId = orderDetail.attachments[currentAttachmentIndex].id

    // resolve topic
    const onNext = (response) => {
      if (response.success) {
        annotationResolveService.emitReload({
          topicId: topicId,
          isResolve: true,
        })
      }
    }

    const onComplete = () => {
      guestReviewerService.emitReload({
        isReload: true,
        attachmentId: attachmentId,
        versionId: versionId,
      })
    }

    const onError = () => {
      toastCenter.messageServerError()
    }

    let data = new FormHandler()
    data.append('data', true)

    const editAnnotationAPI = new EditTopicAnnotationAPI()
    editAnnotationAPI.subscribe(
      accessToken,
      orderId,
      attachmentId,
      versionId,
      topicId,
      typeAnnotationEdit.RESOLVE,
      data.all(),
      onNext,
      onComplete,
      onError
    )
  }

  const handleReopenComment = (versionId, topicId) => {
    const orderId = orderDetail.orderId
    const attachmentId = orderDetail.attachments[currentAttachmentIndex].id

    // reopen topic
    const onNext = (response) => {
      if (response.success) {
        annotationResolveService.emitReload({
          topicId: topicId,
          isResolve: false,
        })
      }
    }

    const onComplete = () => {
      guestReviewerService.emitReload({
        isReload: true,
        attachmentId: attachmentId,
        versionId: versionId,
      })
    }

    const onError = () => {
      toastCenter.messageServerError()
    }

    let data = new FormHandler()
    data.append('data', false)

    const editAnnotationAPI = new EditTopicAnnotationAPI()
    editAnnotationAPI.subscribe(
      accessToken,
      orderId,
      attachmentId,
      versionId,
      topicId,
      typeAnnotationEdit.RESOLVE,
      data.all(),
      onNext,
      onComplete,
      onError
    )
  }

  const handleChangeVersion = (versionId) => {
    const annotationDetailApi = new AnnotationDetailAPI()

    if (accessToken !== '' && orderDetail !== null) {
      const onNext = (response) => {
        if (response.success) {
          setAnnotations(response.data)
        }
        setIsLoadAnnotation(false)
      }
      const onComplete = () => {}
      const onError = () => {
        toastCenter.messageServerError()
        setIsLoadAnnotation(false)
      }

      const orderId = orderDetail.orderId
      const attachmentId = orderDetail.attachments[currentAttachmentIndex].id

      annotationDetailApi.subscribe(
        accessToken,
        orderId,
        attachmentId,
        versionId,
        onNext,
        onComplete,
        onError
      )
    }
  }

  const handleNavNext = (callback) => {
    if (orderDetail.attachments.length > 1) {
      setIsLoadAnnotation(true)

      let nextIndex = currentAttachmentIndex
      if (currentAttachmentIndex === orderDetail.attachments.length - 1) {
        nextIndex = 0
      } else {
        nextIndex += 1
      }

      const initialAttachment = orderDetail.attachments[nextIndex]
      const initVersions = initialAttachment.versions.sort((a, b) => {
        if (a.version < b.version) {
          return -1
        }
        if (a.version > b.version) {
          return 1
        }
        return 0
      })

      const versionId = orderDetail.attachments[nextIndex].versions[0].id

      setVersions([initVersions[initVersions.length - 1]])
      setCurrentAttachmentIndex(nextIndex)
      guestReviewerService.emitReload({
        isReload: true,
        attachmentId: nextIndex,
        versionId: versionId,
        callback: callback,
      })
    }
  }

  const handleNavPrev = (callback = null) => {
    if (orderDetail.attachments.length > 1) {
      setIsLoadAnnotation(true)

      let prevIndex = currentAttachmentIndex
      if (currentAttachmentIndex === 0) {
        prevIndex = orderDetail.attachments.length - 1
      } else {
        prevIndex -= 1
      }

      const initialAttachment = orderDetail.attachments[prevIndex]
      const initVersions = initialAttachment.versions.sort((a, b) => {
        if (a.version < b.version) {
          return -1
        }
        if (a.version > b.version) {
          return 1
        }
        return 0
      })

      const versionId = orderDetail.attachments[prevIndex].versions[0].id

      setVersions([initVersions[initVersions.length - 1]])
      setCurrentAttachmentIndex(prevIndex)
      guestReviewerService.emitReload({
        isReload: true,
        attachmentId: prevIndex,
        versionId: versionId,
        callback: callback,
      })
    }
  }

  // customer only
  const handleActionMakeChange = () => {}
  const handleActionStayInReview = () => {}
  const handleActionPutOnHold = () => {}
  const handleActionOrderApproved = () => {}

  // disabled
  // ONLY APPROVED ORDER ABLE TO DOWNLOAD
  const handleDownloadFile = () => {}
  const handleOpenNewTab = () => {}
  const handleCopyFileUrl = () => {}
  const handleClose = () => {}
  //#endregion

  return (
    <AbilityContext.Provider value={ability}>
      <Helmet>
        <title>Guest Reviewer</title>
      </Helmet>
      <Annotation
        currentUser={guest}
        listMentions={[]}
        versionIndex={versionIndex}
        data={annotations}
        isLoadAnnotation={isLoadAnnotation}
        listVersion={versions}
        useVersion={false}
        useClose={false}
        useNavigation={isUseNavigation}
        onChangeVersion={handleChangeVersion}
        onDownloadFile={handleDownloadFile}
        onOpenNewTab={handleOpenNewTab}
        onCopyFileUrl={handleCopyFileUrl}
        onSendComment={handleSendComment}
        onSaveEditComment={handleSaveEditComment}
        onDeleteComment={handleDeleteComment}
        onResolveComment={handleResolveComment}
        onReopenComment={handleReopenComment}
        onClose={handleClose}
        onNavNext={handleNavNext}
        onNavPrev={handleNavPrev}
        onActionMakeChange={handleActionMakeChange}
        onActionStayInReview={handleActionStayInReview}
        onActionPutOnHold={handleActionPutOnHold}
        onActionOrderApproved={handleActionOrderApproved}
        ability={[]}
        onApproveVersion={null}
        onReviseVersion={null}
        currentUserRole={userRole.GUEST}
        attachmentTypeStartFrom={attachmentTypeStartFromStruct.GUEST_REVIEW}
        byteLength={byteLength}
      />
      {isBrokenLink && <BrokenLink />}
      {!isBrokenLink && isMessageWelcome && guest ? (
        <MessageWelcome
          guest={guest}
          onHide={() => setIsMessageWelcome(false)}
        />
      ) : null}
    </AbilityContext.Provider>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiStart: () => dispatch(actions.apiStart()),
    apiStop: () => dispatch(actions.apiStop()),
  }
}

export default connect(null, mapDispatchToProps)(GuestAnnotation)
