import { isToday, isYesterday, isThisYear } from 'date-fns'
import { format, toZonedTime, fromZonedTime } from 'date-fns-tz'
import monthLists from './monthLists'

export const isPublicHoliday = (date) => {
  switch (date.getDay()) {
    case 0:
    case 6:
      return true
    default:
      return false
  }
}

export const isWeekDay = (date) => {
  switch (date.getDay()) {
    case 0:
    case 6:
      return false
    default:
      return true
  }
}

export const notificationDisplayDate = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000)

  var interval = Math.floor(seconds / 31536000)

  if (interval > 1) {
    return interval + ' years'
  }
  interval = Math.floor(seconds / 2592000)
  if (interval > 1) {
    return interval + ' months'
  }
  interval = Math.floor(seconds / 86400)
  if (interval > 1) {
    return interval + ' days'
  }
  interval = Math.floor(seconds / 3600)
  if (interval > 1) {
    return interval + ' hours'
  }
  interval = Math.floor(seconds / 60)
  if (interval > 1) {
    return interval + ' minutes'
  }
  return Math.floor(seconds) + ' seconds'
}

export const humanDisplayDate = (date) => {
  if (isToday(date)) {
    return 'Today'
  } else if (isYesterday(date)) {
    return 'Yesterday'
  } else if (isThisYear(date)) {
    return defaultDisplayDate(date)
  }

  return format(date, 'dd MMM yyyy')
}

export const timelineDisplayDate = (date) => {
  return `${format(date, 'MMMM D, yyyy')} at ${format(date, 'HH.mm')}`
}

export const fullDisplayDate = (date) => {
  return `${format(date, 'dd MMM yyyy')} / ${format(date, 'HH:mm')}`
}

export const defaultDisplayDate = (date) => {
  return `${format(date, 'dd MMM yyyy')}`
}
export const versioningtDisplayDate = (date) => {
  return `${format(date, 'dd MMM yyyy HH:mm')}`
}

export const defaultDisplayHeaderDate = (date) => {
  return `${format(date, 'MMMM yyyy')}`
}

export const getMonthName = (key) => {
  const getMonth = monthLists[key]
  return getMonth !== undefined ? getMonth : monthLists[0]
}

export const getMonthShorthand = (month) => {
  const temp = parseInt(month, 10).toString()
  return temp.length < 2 ? `0${temp}` : temp
}

export const getYearShorthand = (year) => {
  const temp = parseInt(year, 10).toString()
  return temp.substr(2)
}

export const getFullYear = (yearShorthand) => {
  let prefix = new Date().getFullYear()
  prefix = prefix.toString().substring(0, prefix.toString().length - 2)
  return prefix + yearShorthand
}

export const formatCardExpiry = (expiryDate) => {
  if (!expiryDate) {
    return ''
  }

  const arr = expiryDate.replace(/\s/g, '').split('/')
  const month = getMonthShorthand(arr[0])
  return `${month}/${arr[1]}`
}

export const convertToUserTimezone = (dateTime, timeZone) => {
  const { utc } = timeZone
  // const localDateTime = dateTime.replace('Z', '')
  const newDate = new Date(dateTime)
  // If date is declared
  if (newDate instanceof Object) {
    const userGMT = toZonedTime(newDate, utc)
    const utcTimeZone = format(userGMT, 'dd MMMM yyyy / HH:mm (z)', {
      timeZone: utc,
    })
    const simpleFormatDate = format(userGMT, 'dd MMM yyyy / HH:mm (z)', {
      timeZone: utc,
    })
    const response = {
      date: dateTime,
      utcTimeZone: utcTimeZone,
      label: userGMT.toString(),
      iso: userGMT.toISOString(),
      timeZone: userGMT,
      formattedDate: utcTimeZone,
      simpleFormatDate: simpleFormatDate,
    }
    return response
  }
  return false
}

export const getDateDiff = (start, end) => {
  const stampDiff = new Date(start).getTime() - new Date(end).getTime()
  return new Date(stampDiff)
}

export const convertToUTC = (date) => {
  // return fromZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
  return new Date(
    fromZonedTime(
      date,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    ).toUTCString()
  )
}

export const convertToLocaltime = (date, timezone) => {
  return toZonedTime(
    fromZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone),
    timezone
  )
}

export const timeConvert = (n) => {
  var num = n
  var hours = num / 60
  var rhours = Math.floor(hours)
  var minutes = (hours - rhours) * 60
  var rminutes = Math.round(minutes)

  if (rhours > 0) {
    return rhours + ' hrs ' + rminutes + ' mins'
  }

  return rminutes + ' minute(s)'
}
