import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as Icon from 'react-feather'
import { Transition } from 'react-transition-group'
import tippy from 'tippy.js'

import './MembershipStorage.scss'
// import MembershipButtonPopover from '../MembershipButtonPopover/MembershipButtonPopover';
import ProgressBar from '../../ProgressBar/ProgressBar'
// import * as Router from '../../../shared/router';
// import { Can } from '../../../context/abilityContext';
// import { abilityAction, abilityComponent } from '../../../shared/ability';
import { formatBytes } from '../../../shared/fileHelper'

const duration = 350

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  height: 0,
  overflow: 'hidden',
}

const MembershipStorage = ({ user, isLoading, style }) => {
  const [isExpandAdditional, setIsExpandAdditional] = useState(false)
  const [subscriptionRetail, setSubscriptionRetail] = useState(0)
  const containerRef = useRef()
  const [customTippy, setCustomTippy] = useState()

  useEffect(() => {
    const additionalStoragePopup = document.getElementById(
      'additional-storage-popup'
    )

    if (customTippy)
      customTippy.setProps({
        content: additionalStoragePopup.innerHTML,
        animation: 'shift-away',
        zIndex: 1020,
        appendTo: containerRef.current,
        allowHTML: true,
      })
  }, [customTippy])

  useEffect(() => {
    if (user !== null) {
      let totalSubscription = 0

      totalSubscription += parseInt(
        user.retail ? user.retail.additionalStorageQuota : 0
      )
      if (totalSubscription > 0) setSubscriptionRetail(totalSubscription)
    }

    const additionalStoragePopup = document.getElementById(
      'additional-storage-popup'
    )

    const customTippy = tippy(containerRef.current, {
      content: additionalStoragePopup.innerHTML,
      animation: 'shift-away',
      zIndex: 1020,
      appendTo: containerRef.current,
      allowHTML: true,
    })
    setCustomTippy(customTippy)
  }, [user])

  if ((user === null && isLoading) || (user === null && !isLoading)) {
    return null
  }

  const additionals = user.storages.additionals

  const customerUsed = parseInt(user.storages.used)
  const customerQuota = parseInt(user.storages.quota)
  const used =
    customerUsed + (user.retail ? user.retail.additionalStorageUsed : 0)
  const space =
    customerQuota === 0
      ? 0
      : parseInt(customerQuota) +
        parseInt(user.retail ? user.retail.additionalStorageQuota : 0)

  const percentOfUsed = customerUsed !== 0 ? (used / space) * 100 : 0

  const percentOfUsedTitle =
    customerUsed !== 0 && percentOfUsed > 0
      ? `(${percentOfUsed.toFixed(1)})%`
      : '(0%)'

  const isWarningFull = parseInt(percentOfUsed) >= 90

  const heightContent = 22 * 4

  const transitionStyles = {
    entered: { height: heightContent, visibility: 'visible' },
    exited: { height: 0, visibility: 'hidden' },
  }

  const handleExpandAdditionalStorage = () => {
    setIsExpandAdditional(!isExpandAdditional)
  }

  return (
    <div
      className="membership-status"
      ref={containerRef}
      style={Object.assign(style, {})}
    >
      {/* 
        Tippy custom HTML
      */}
      <div id="additional-storage-popup" style={{ display: 'none' }}>
        <span>
          Your allocated subscription storage minus the amount used for your
          orders.
        </span>
        {subscriptionRetail > 0 && (
          <>
            <div>
              <div className="d-flex justify-content-between mt-2">
                <div>{user.membership.title} membership</div>
                <div>{formatBytes(parseInt(user.storages.quota))}</div>
              </div>
              {subscriptionRetail > 0 ? (
                <div className="d-flex justify-content-between mt-1 text-subscription-retail">
                  <div>Subscription Additional Storage</div>
                  <div>
                    {formatBytes(parseInt(user.retail.additionalStorageQuota))}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </div>
      <div className="clearfix text-membership-status w-100">
        <div className="float-left d-flex">
          <span className="text-12 text-light">Storage</span>
          {subscriptionRetail > 0 && (
            <span className="dot-subscription-retail"> </span>
          )}
        </div>
        <div className="float-right clearfix d-flex align-items-center">
          <div
            className={`text-12 d-flex flex-row align-items-center ${
              user.storages.quota !== '&infin;' && isWarningFull
                ? 'text-danger'
                : 'text-light'
            }`}
          >
            <strong>{formatBytes(parseInt(used))}</strong>
            <span>&nbsp;/&nbsp;</span>
            {user.storages.quota === '&infin;' ? (
              <span
                dangerouslySetInnerHTML={{ __html: '&infin;' }}
                className="text-18 font-weight-bold line-height-10"
              ></span>
            ) : (
              <strong>{formatBytes(parseInt(space))}</strong>
            )}
            <span>&nbsp;{percentOfUsedTitle}</span>
          </div>

          {/* <Can I={abilityAction.READ} a={abilityComponent.NAV_MANAGE_STORAGE}>
            <MembershipButtonPopover
              onClick={upgradeHandleClick}
              title={'Storage Management'}
              content={'Subscribe to monthly plan to add additional storage space to store more files for your orders.'}
              btnText="Manage"
              style={{ float: 'right' }}
              isWarningFull={user.storages.quota !== '&infin;' && isWarningFull} />
          </Can> */}
        </div>
      </div>
      <ProgressBar
        valuenow={used}
        valuemin={0}
        valuemax={space}
        isGradient={user.storages.quota === '&infin;'}
      />

      {additionals && additionals.length > 0 && (
        <div className="d-flex flex-column position-relative">
          <button
            className={`btn btn-icon text-light btn-expand-storage ${
              isExpandAdditional && 'expanded'
            }`}
            onClick={handleExpandAdditionalStorage}
          >
            <Icon.ChevronDown className="feather-20" />
          </button>

          <div className="d-flex flex-row mt-3">
            <div className="d-flex">
              <span
                className={
                  isWarningFull
                    ? 'dot dot-12 dot-danger'
                    : 'dot dot-12 dot-primary'
                }
              ></span>
            </div>
            <div className="d-flex flex-grow-1 line-height-12 ml-2">
              <span className="text-light text-10">Additional +5GB /</span>
              &nbsp;
              <span
                className={
                  isWarningFull ? ' text-10 text-danger' : ' text-10 text-light'
                }
              >
                {isWarningFull ? 'Overdue' : 'Due'}&nbsp;13 Aug 2019
              </span>
            </div>
          </div>

          <Transition
            in={isExpandAdditional}
            timeout={duration}
            mountOnEnter
            unmountOnExit
          >
            {(state) => (
              <div
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state],
                }}
              >
                <ul className="list-unstyled m-0">
                  <li className="pt-3">
                    <div className="d-flex flex-row">
                      <div className="d-flex">
                        <span
                          className={
                            isWarningFull
                              ? 'dot dot-12 dot-danger'
                              : 'dot dot-12 dot-primary'
                          }
                        ></span>
                      </div>
                      <div className="d-flex flex-grow-1 line-height-12 ml-2">
                        <span className="text-light text-10">
                          Additional +5GB /&nbsp;
                        </span>
                        <span
                          className={
                            isWarningFull
                              ? ' text-10 text-danger'
                              : ' text-10 text-light'
                          }
                        >
                          {isWarningFull ? 'Overdue' : 'Due'}&nbsp;13 Aug 2019
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="pt-3">
                    <div className="d-flex flex-row">
                      <div className="d-flex">
                        <span
                          className={
                            isWarningFull
                              ? 'dot dot-12 dot-danger'
                              : 'dot dot-12 dot-primary'
                          }
                        ></span>
                      </div>
                      <div className="d-flex flex-grow-1 line-height-12 ml-2">
                        <span className="text-light text-10">
                          Additional +5GB /&nbsp;
                        </span>
                        <span
                          className={
                            isWarningFull
                              ? ' text-10 text-danger'
                              : ' text-10 text-light'
                          }
                        >
                          {isWarningFull ? 'Overdue' : 'Due'}&nbsp;13 Aug 2019
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="pt-3">
                    <div className="d-flex flex-row">
                      <div className="d-flex">
                        <span
                          className={
                            isWarningFull
                              ? 'dot dot-12 dot-danger'
                              : 'dot dot-12 dot-primary'
                          }
                        ></span>
                      </div>
                      <div className="d-flex flex-grow-1 line-height-12 ml-2">
                        <span className="text-light text-10">
                          Additional +5GB /&nbsp;
                        </span>
                        <span
                          className={
                            isWarningFull
                              ? ' text-10 text-danger'
                              : ' text-10 text-light'
                          }
                        >
                          {isWarningFull ? 'Overdue' : 'Due'}&nbsp;13 Aug 2019
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="pt-3">
                    <div className="d-flex flex-row">
                      <div className="d-flex">
                        <span
                          className={
                            isWarningFull
                              ? 'dot dot-12 dot-danger'
                              : 'dot dot-12 dot-primary'
                          }
                        ></span>
                      </div>
                      <div className="d-flex flex-grow-1 line-height-12 ml-2">
                        <span className="text-light text-10">
                          Additional +5GB /&nbsp;
                        </span>
                        <span
                          className={
                            isWarningFull
                              ? ' text-10 text-danger'
                              : ' text-10 text-light'
                          }
                        >
                          {isWarningFull ? 'Overdue' : 'Due'}&nbsp;13 Aug 2019
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </Transition>
        </div>
      )}
    </div>
  )
}

MembershipStorage.propTypes = {
  style: PropTypes.object,
}

export default (MembershipStorage)
