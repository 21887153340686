import React, { useState, useRef, useEffect, useContext } from 'react'
import { EditorState, convertFromRaw } from 'draft-js'
import Editor from '@draft-js-plugins/editor'
import createMentionPlugin from '@draft-js-plugins/mention'
import createEmojiPlugin from '@draft-js-plugins/emoji'
import * as Icon from 'react-feather'
import tippy from 'tippy.js'

import 'draft-js/dist/Draft.css'
import '@draft-js-plugins/mention/lib/plugin.css'
import '@draft-js-plugins/emoji/lib/plugin.css'
import 'tippy.js/themes/light.css'

import './Comment.scss'
import { setStrokeContent, setClearStrokeContent } from '../../shared/utils'
import Avatar from '../../../Avatar/Avatar'
import { convertToUserTimezone } from '../../../../shared/dateTimeHelper'
import { VersionContext } from '../../context/versionContext'
import { ShapeContext } from '../../context/shapeContext'

const Comment = ({
  comment,
  isMinimize,
  onEditComment,
  onResolveComment,
  onReopenComment,
  onDeleteComment,
  onShowConfirmDelete,
  isTooltip = false,
  isResolve = false,
  isReadOnly = false,
}) => {
  const versionContext = useContext(VersionContext)

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [isShowMenu, setIsShowMenu] = useState(false)
  const [isShowComfirm, setIsShowComfirm] = useState(false)
  const [menuTop, setMenuTop] = useState(0)
  const [menuLeft, setMenuLeft] = useState(0)
  const [{ plugins }] = useState(() => {
    const mentionPlugin = createMentionPlugin({
      mentionPrefix: '@',
      supportWhitespace: true,
      entityMutability: 'IMMUTABLE',
      mentionComponent: ({ entityKey, children, mention }) => (
        <span
          key={entityKey}
          className="mentioned-user mentioned-user-popover"
          data-tippy-content={JSON.stringify(mention)}
        >
          {children}
        </span>
      ),
    })
    const emojiPlugin = createEmojiPlugin()

    const plugins = [mentionPlugin, emojiPlugin]
    return {
      plugins,
    }
  })

  const wrapperRef = useRef(null)
  const wrapperConfirmRef = useRef(null)
  const shapeContext = useContext(ShapeContext)

  const commentMessage = comment.message
  const user = shapeContext.user

  useEffect(() => {
    let timer = null

    if (
      commentMessage !== undefined &&
      commentMessage !== null &&
      commentMessage !== ''
    ) {
      const message = JSON.parse(commentMessage)
      const editorStateComment = EditorState.createWithContent(
        convertFromRaw(message)
      )

      // async wait until all draftjs get ready
      timer = setTimeout(() => {
        if (isResolve) {
          const newContent = setStrokeContent(editorStateComment)
          setEditorState(EditorState.createWithContent(newContent))
        } else {
          const newContent = setClearStrokeContent(editorStateComment)
          setEditorState(EditorState.createWithContent(newContent))
        }
      }, 50)
    }

    const timer2 = setTimeout(() => {
      tippy('.mentioned-user-popover', {
        animation: 'shift-away',
        theme: 'light',
        onTrigger(instance, event) {
          const mention = JSON.parse(instance.reference.dataset.tippyContent)
          instance.setContent(`
          <div class="d-flex flex-row">
            <div class="d-flex mr-3">
              <div style="background-image:url(${mention.avatar})" class="d-flex rounded-circle user-avatar-32 user-avatar-cover"></div>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex font-weight-bold">${mention.name}</div>
              <div class="d-flex">${mention.position}</div>
            </div>
          </div>
          `)
        },
      })
    }, 100)

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
      clearTimeout(timer2)
    }
  }, [commentMessage, isResolve])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const handleShowMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (isTooltip) {
      setMenuTop(24)
      setMenuLeft(-38)
    } else {
      setMenuTop(24)
      setMenuLeft(-38)
    }

    setIsShowMenu(!isShowMenu)
  }

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setIsShowMenu(false)
    }

    if (
      wrapperConfirmRef.current &&
      !wrapperConfirmRef.current.contains(e.target)
    ) {
      setIsShowComfirm(false)
    }
  }

  const handleEdit = () => {
    setIsShowMenu(false)
    onEditComment(editorState, comment.id)
  }

  const handleShowConfirmDelete = () => {
    setIsShowMenu(false)
    setIsShowComfirm(true)
    onShowConfirmDelete()
  }

  const handleDeleteComment = (versionId, commentId, isTopic) => {
    setIsShowComfirm(false)
    onDeleteComment(versionId, commentId, isTopic)
  }

  const handleResolveComment = (topicId) => {
    onResolveComment(topicId)
  }

  const handleReopenComment = (topicId) => {
    onReopenComment(topicId)
  }

  return (
    <>
      <li
        className={`comment-item ${
          comment.isTopic && isMinimize ? 'comment-parent' : ''
        } ${!comment.isTopic && isMinimize ? 'comment-hide' : ''}`}
      >
        <div className="comment-wrapp">
          <div className="d-flex avatar-wrapper avatar-wrapper-comment-annotaion">
            <Avatar
              src={comment.user.avatar}
              user={{ firstname: comment.user.name }}
              radius={32}
            />
          </div>
          <div className="d-flex flex-column flex-grow-1 ml-3 max-right">
            <div className="d-flex flex-row header-comment">
              <div
                className="d-flex flex-grow-1 flex-column mb-3"
                style={{ flexWrap: 'wrap' }}
              >
                <span className="font-weight-bold">{comment.user.name}</span>
                <span className={`text-date text-11`}>
                  {
                    convertToUserTimezone(comment.dateCreated, user.timeZone)
                      .simpleFormatDate
                  }
                </span>
              </div>

              <div className="d-flex flex-row align-items-start">
                {!isReadOnly && (
                  <>
                    {comment.isTopic && !isResolve && (
                      <button
                        type="button"
                        className="btn btn-resolve mr-3"
                        onClick={() => handleResolveComment(comment.id)}
                      >
                        <Icon.Circle className="circle-icon" />
                        <span>Resolve</span>
                      </button>
                    )}
                    {comment.isTopic && isResolve && (
                      <button
                        type="button"
                        className="btn btn-resolve mr-3"
                        onClick={() => handleReopenComment(comment.id)}
                      >
                        <Icon.Circle className="circle-icon text-primary" />
                        <span>Reopen</span>
                      </button>
                    )}
                  </>
                )}

                {/* only own user can update message */}
                {!isReadOnly && comment.user.id && comment.user.id === user.id && (
                  <div className="position-relative" ref={wrapperRef}>
                    <button
                      type="button"
                      className="btn btn-more"
                      onClick={handleShowMenu}
                    >
                      <Icon.MoreHorizontal className="more-icon" />
                    </button>

                    {isShowMenu && (
                      <div
                        className="menu-comment"
                        style={{
                          top: `${menuTop}px`,
                          left: `${menuLeft}px`,
                        }}
                      >
                        <ul className="list-unstyled">
                          <li>
                            <button
                              type="button"
                              className="btn"
                              onClick={handleEdit}
                            >
                              Edit
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              className="btn btn-delete"
                              onClick={handleShowConfirmDelete}
                            >
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex comment-content">
              <Editor
                readOnly
                plugins={plugins}
                editorState={editorState}
                onChange={setEditorState}
              />
            </div>

            {isShowComfirm && (
              <div className="confirm-delete mt-2 mb-3" ref={wrapperConfirmRef}>
                <div className="d-flex flex-column mb-2">
                  <div>
                    {comment.isTopic ? (
                      <span>Are you sure want to delete topic?</span>
                    ) : (
                      <span>Are you sure want to delete comment?</span>
                    )}
                  </div>
                  <div>
                    {comment.isTopic ? (
                      <span className="info-delete">
                        Delete topic will delete comment and annotation. This
                        cannot be undone.
                      </span>
                    ) : (
                      <span className="info-delete">
                        This cannot be undone.
                      </span>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <button
                    type="button"
                    className="btn btn-danger mr-2 btn-sm"
                    onClick={() =>
                      handleDeleteComment(
                        versionContext.selectedVersion.id,
                        comment.id,
                        comment.isTopic
                      )
                    }
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={() => setIsShowComfirm(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </li>
    </>
  )
}

export default Comment
