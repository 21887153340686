import React from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'

import * as Router from './shared/router'
import Logout from './containers/Auth/Logout/Logout'
import DashboardContainer from './containers/DashboardContainer/DashboardContainer'
// import PdfViewerContainer from './containers/PdfViewer/PdfViewerContainer';
import AnnotationContainer from './containers/AnnotationContainer/AnnotationContainer'

import Login from './containers/Auth/Auth'
import LoginAdmin from './containers/Auth/AuthAdmin'
import GuestAnnotation from './containers/GuestAnnotation/GuestAnnotation'
import ForgotPassword from './containers/Auth/ForgotPassword/ForgotPassword'
import ClearhHistory from './containers/ClearhHistory/ClearhHistory'
import NotSupportBrowser from './containers/NotSupportBrowser/NotSupportBrowser'
import ImpersonateCustomer from './containers/Impersonate/ImpersonateCustomer'

const router = ({ isAuthenticated }) => {
  let routes = isAuthenticated ? (
    <Routes>
      <Route path={Router.signout} element={<Logout />} />
      <Route path={Router.guestAnnotation} element={<GuestAnnotation />} />
      <Route path={Router.attachmentDetail} element={<AnnotationContainer />} />
      <Route path={Router.versionDetail} element={<AnnotationContainer />} />
      <Route path="*" element={<DashboardContainer />} />
    </Routes>
  ) : (
    <Routes>
      <Route path={Router.signin} element={<Login />} />
      <Route path={Router.forgotPassword} element={<ForgotPassword />} />
      <Route path={Router.signinAdmin} element={<LoginAdmin />} />
      <Route path={Router.clearhHistory} element={<ClearhHistory />} />
      <Route path={Router.notSupport} element={<NotSupportBrowser />} />
      <Route
        path={Router.impersonateCustomer}
        element={<ImpersonateCustomer />}
      />
      <Route path={Router.guestAnnotation} element={<GuestAnnotation />} />
      <Route path="*" element={<Navigate to={Router.signin} replace />} />
    </Routes>
  )

  return routes
}

export default router
