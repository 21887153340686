import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink, Link } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import feather from 'feather-icons'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import './NavBar.scss'
import * as RouterPath from '../../../shared/router'
// import * as actions from '../../../store/actions/index'
import UserMenu from '../UserMenu/UserMenu'
import NotificationList from '../NotificationList/NotificationList'
import PaygCart from '../PaygCart/PaygCart'
// import Cart from '../Cart/Cart';
import * as Router from '../../../shared/router'
import { Can } from '../../../context/abilityContext'
import { abilityAction, abilityComponent } from '../../../shared/ability'
import {
  truncateText,
  getCurrentView,
  handleLogo,
} from '../../../shared/utility'
import QuickSearch from '../../QuickSearch/QuickSearch'
import QuickSearchArchived from '../../QuickSearchArchived/QuickSearchArchived'
import FeatherIcon from '../../FeatherIcon/FeatherIcon'
import logoTypes from '../../../shared/logoTypes'
// import CreateOrder from '../../PaygCreateOrderModal/CreateOrder'

const duration = 300
// const positionMove = 48 + 49;

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  // transform: `translateX(-${positionMove}px)`,
  opacity: 0,
  height: '100%',
  width: '0',
}

const transitionStyles = {
  // entering: { opacity: 1, transform: 'translateX(0)' },
  // entered: { opacity: 1, transform: 'translateX(0)' },
  // exiting: { opacity: 0, transform: `translateX(-${positionMove}px)` },
  // exited: { opacity: 0, transform: `translateX(-${positionMove}px)` },
  entering: { opacity: 1, width: '97px' },
  entered: { opacity: 1, width: '97px' },
  exiting: { opacity: 0, width: '0' },
  exited: { opacity: 0, width: '0' },
}

const defaultNavStyle = {
  transition: `all ${duration}ms ease-in-out`,
  // transform: `translateX(-${positionMove}px)`,
  height: '100%',
  display: 'flex',
  flexBasis: 'auto',
}

const transitionNavStyles = {
  // entering: { transform: 'translateX(0)' },
  // entered: { transform: 'translateX(0)' },
  // exiting: { transform: `translateX(-${positionMove}px)` },
  // exited: { transform: `translateX(-${positionMove}px)` },
}

const NavBar = ({ isFullScreen, onToggle, userDetail, isLoading }) => {
  const location = useLocation()
  useEffect(() => {
    feather.replace()
  }, [])

  if (userDetail === null && isLoading) {
    return null
  }

  const handleCreateButton = (user) => {
    if (getCurrentView(user) === 'client') {
      if (location.pathname !== RouterPath.createCustomer) {
        return (
          <li className="nav-item mr-3 li-create-order">
            <NavLink
              to={Router.createCustomer}
              className="btn btn-darkprimary d-flex align-items-center justify-content-center btn-create-order"
            >
              <FeatherIcon name="edit" />
              <span className="d-none d-xl-inline">Create New Customer</span>
            </NavLink>
          </li>
        )
      }
    }

    const navLinkClass = `btn btn-darkprimary d-flex align-items-center justify-content-center btn-create-order`

    return (
      <>
        <Can I={abilityAction.READ} a={abilityComponent.CREATE_ORDER}>
          {location.pathname !== RouterPath.createOrder &&
            location.pathname !== RouterPath.createCustomer &&
            location.pathname !== RouterPath.myImages && (
              <li className="nav-item mr-3 li-create-order">
                {user && user.membership && user.membership.type === 'free' ? (
                  <Link
                    to={{
                      pathname: Router.createOrder,
                      state: { paygOrder: true },
                    }}
                    style={{ textDecoration: 'none' }}
                  >
                    <button
                      type="button"
                      className="btn btn-darkprimary d-flex align-items-center justify-content-center btn-create-order"
                      // onClick={() => handleCreateOrderClick()}
                    >
                      <FeatherIcon name="edit" />
                      <span>Create New Order</span>
                    </button>
                  </Link>
                ) : (
                  // <button
                  //   type="button"
                  //   className="btn btn-darkprimary d-flex align-items-center justify-content-center btn-create-order"
                  //   onClick={() => handleCreateOrderClick()}
                  // >
                  //   <FeatherIcon name="edit" />
                  //   <span>Create New Order</span>
                  // </button>
                  <NavLink to={Router.createOrder} className={navLinkClass}>
                    <FeatherIcon name="edit" />
                    <span className="d-none d-xl-inline">Create New Order</span>
                  </NavLink>
                )}
              </li>
            )}
        </Can>
      </>
    )
  }

  return (
    <nav className="navbar navbar-top-custom navbar-expand-lg navbar-light">
      <Transition in={isFullScreen} timeout={duration}>
        {(state) => (
          <div
            className="d-flex"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <NavLink to="/" className="d-flex align-items-center">
              {handleLogo(userDetail, logoTypes.FAVICON)}
            </NavLink>

            <button
              type="button"
              className="btn btn-icon btn-toggle-header"
              onClick={onToggle}
            >
              <i data-feather="log-out"></i>
            </button>
          </div>
        )}
      </Transition>

      <Transition in={isFullScreen} timeout={duration}>
        {(state) => (
          <div
            className="collapse navbar-collapse"
            style={{
              ...defaultNavStyle,
              ...transitionNavStyles[state],
            }}
          >
            <ul className="navbar-nav mr-auto">
              {handleCreateButton(userDetail)}
              {location.pathname === RouterPath.home && (
                <li className="nav-item d-flex align-items-center">
                  <QuickSearch />
                </li>
              )}
              {(location.pathname === RouterPath.archivedOrder ||
                location.pathname === RouterPath.orderListView) && (
                <li className="nav-item d-flex align-items-center">
                  <QuickSearchArchived />
                </li>
              )}
            </ul>
          </div>
        )}
      </Transition>

      <div className="top-link-container ml-auto">
        <div className="d-flex align-items-center">
          {userDetail && (
            <span className="message-welcome d-none d-md-none d-lg-flex">
              Welcome back, {truncateText(userDetail.firstname, 25)}!
            </span>
          )}
        </div>
        <UserMenu />
        <NotificationList />
        {userDetail.membership && (
          <Can I={abilityAction.READ} a={abilityComponent.PAYG_CART}>
            {userDetail.membership.type === 'free' && <PaygCart />}
          </Can>
        )}

        {/* <Can I={abilityAction.READ} a={abilityComponent.NAV_CART}>
          <Cart />
        </Can> */}
      </div>
    </nav>
  )
}

NavBar.propTypes = {
  isFullScreen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user }) => {
  return { userDetail: user }
}

export default connect(mapStateToProps)(NavBar)
