import React, { useContext, useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Icon from 'react-feather'
import tippy from 'tippy.js'
import 'tippy.js/themes/light.css'

import './HeaderAnnotation.scss'
import { HeaderAnnotationContext } from '../../context/headerAnnotationContext'
import { AnnotationActionContext } from '../../context/annotationActionContext'
import { VersionContext } from '../../context/versionContext'
import { PreviewContext } from '../../context/previewContext'
import {
  typeDisplayAnnotation,
  reviewerAbilityStruct,
  attachmentTypeStartFromStruct,
} from '../../shared/constants'
import { ReviewerContext } from '../../context/reviewerContext'
import { AbilityContext } from '../../../../context/abilityContext'
import { abilityAction, abilityComponent } from '../../../../shared/ability'
import { PdfContext } from '../../context/pdfContext'
import Downloading from './Downloading'

const HeaderAnnotation = ({
  // publicCommentCount = 0,
  // internalCommentCount = 0,
  useClose = true,
  onDownloadFile = () => {},
  isDownloadFile = false,
  // useVersion = true,
}) => {
  const {
    versions,
    selectedInternalVersionIndex,
    selectedPublicVersionIndex,
    attachmentTypeStartFrom,
  } = useContext(VersionContext)
  const previewContext = useContext(PreviewContext)
  const {
    ability,
    onActionMakeChange,
    onActionOrderApproved,
    onActionPutOnHold,
    onActionStayInReview,
  } = useContext(ReviewerContext)
  const {
    // onShowVersionPanel,
    // onShowCommentPanel,
    onShowInternalComment,
    onShowPublicComment,
    // isShowCommentPanel,
    // isShowVersionPanel,
    isInternalTabDisabled,
    isPublicTabDisabled,
    isShowPublicComment,
  } = useContext(HeaderAnnotationContext)
  const pdfContext = useContext(PdfContext)
  const annotationActionContext = useContext(AnnotationActionContext)
  const abilityContext = useContext(AbilityContext)

  const location = useLocation()
  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])
  const navigate = useNavigate()

  let currentVersion = versions.internal[selectedInternalVersionIndex]

  if (isShowPublicComment) {
    currentVersion = versions.public[selectedPublicVersionIndex]
  }

  useEffect(() => {
    tippy('.btn-action-status, .btn-download', {
      animation: 'shift-away',
      theme: 'light',
      onTrigger(instance, _) {
        instance.setContent(instance.reference.dataset.tippyContent)
      },
    })
  }, [ability])

  const handleMakeChange = useCallback(() => {
    if (typeof onActionMakeChange === 'function') {
      onActionMakeChange()
    }
  }, [onActionMakeChange])

  const handleStayInReview = useCallback(() => {
    if (typeof onActionStayInReview === 'function') {
      onActionStayInReview()
    }
  }, [onActionStayInReview])

  const handlePutOnHold = useCallback(() => {
    if (typeof onActionPutOnHold === 'function') {
      onActionPutOnHold()
    }
  }, [onActionPutOnHold])

  const handleOrderApproved = useCallback(() => {
    if (typeof onActionOrderApproved === 'function') {
      onActionOrderApproved()
    }
  }, [onActionOrderApproved])

  const handleChangeTab = useCallback(
    (type = 'internal') => {
      if (
        queryParams &&
        (queryParams.has('versionId') || queryParams.has('commentId'))
      ) {
        queryParams.delete('versionId')
        queryParams.delete('commentId')
      }
      if (type === 'internal') {
        onShowInternalComment()
        queryParams.set('public', false)
      } else {
        onShowPublicComment()
        queryParams.set('public', true)
      }
      navigate({
        search: queryParams.toString(),
      })
    },
    [navigate, onShowInternalComment, onShowPublicComment, queryParams]
  )

  if (!currentVersion) {
    return null
  }

  return (
    <div className="header-annotation-wrapper d-flex flex-column">
      <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-between pl-3 pr-3">
        <div className="d-flex justify-content-center align-items-center">
          <div className="d-flex title">
            <span>{currentVersion.fileName}</span>
            <span>&nbsp;-&nbsp;</span>
            <span>{currentVersion.version}</span>
            {abilityContext.can(
              abilityAction.READ,
              abilityComponent.ACTION_CM_APPROVE
            ) &&
              currentVersion.isInternalApproved && (
                <span className="badge badge-darkprimary">
                  <Icon.CheckCircle className="feather" />
                  <span>Checked</span>
                </span>
              )}
          </div>

          <div className="d-flex">
            <button
              type="button"
              className="btn btn-sm btn-outline-primary ml-3 btn-download d-flex justify-content-center"
              onClick={onDownloadFile}
              data-tippy-content="Download current active screen to pdf."
              disabled={isDownloadFile}
            >
              {isDownloadFile ? (
                <Downloading />
              ) : (
                <Icon.Download className="feather-14 mr-2" />
              )}
              {isDownloadFile ? `Downloading...` : `Download Proof`}
            </button>
          </div>
        </div>

        {previewContext.typeDisplay === typeDisplayAnnotation.PDF && (
          <div className="d-flex flex-column justify-content-center align-items-center ml-4">
            <div className="d-flex">
              <span className="font-weight-bold">Page</span>&nbsp;
              <span className="text-light">
                {pdfContext.activePage} of {pdfContext.totalPage}
              </span>
            </div>
          </div>
        )}
        <div className="d-flex flex-row align-items-center">
          <ul className="list-inline m-0 menu-annotation">
            {ability.length > 0 && (
              <>
                {ability.findIndex(
                  (x) => x === reviewerAbilityStruct.MAKE_CHANGES
                ) > -1 && (
                  <li className="list-inline-item no-border">
                    <button
                      type="button"
                      className={`btn btn-primary btn-sm btn-text-black btn-action-status position-relative`}
                      onClick={handleMakeChange}
                      data-tippy-content="Clicking this button will move the order back to In Progress and inform the Operator it needs changes."
                    >
                      <span>Make Changes</span>
                    </button>
                  </li>
                )}

                {ability.findIndex(
                  (x) => x === reviewerAbilityStruct.STAY_IN_REVIEW
                ) > -1 && (
                  <li className="list-inline-item no-border">
                    <button
                      type="button"
                      className={`btn btn-primary btn-sm btn-text-black btn-action-status position-relative`}
                      onClick={handleStayInReview}
                      data-tippy-content="Clicking this button will close the review screen and the order will remain in Review status."
                    >
                      <span>Stay In Review</span>
                    </button>
                  </li>
                )}

                {ability.findIndex(
                  (x) => x === reviewerAbilityStruct.PUT_ON_HOLD
                ) > -1 && (
                  <li className="list-inline-item no-border">
                    <button
                      type="button"
                      className={`btn btn-warning btn-sm btn-text-black btn-action-status position-relative`}
                      onClick={handlePutOnHold}
                      data-tippy-content="Clicking this button will put the order On Hold from your current active orders. You can activate this Order again by selecting the Resume Order action in the Order Detail screen."
                    >
                      <span>Put On Hold</span>
                    </button>
                  </li>
                )}

                {ability.findIndex(
                  (x) => x === reviewerAbilityStruct.ORDER_APPROVED
                ) > -1 && (
                  <li className="list-inline-item no-border">
                    <button
                      type="button"
                      className={`btn btn-darkprimary btn-sm btn-text-white btn-action-status position-relative`}
                      onClick={handleOrderApproved}
                      data-tippy-content="Clicking this button will Approve the order and it will move to the Approved status."
                    >
                      <span>Order Approved</span>
                    </button>
                  </li>
                )}
              </>
            )}

            {/* {useVersion && (
              <li className="list-inline-item">
                <button
                  type="button"
                  className={`btn btn-header-annotation position-relative ${
                    isShowVersionPanel ? 'active' : ''
                  }`}
                  onClick={onShowVersionPanel}
                >
                  <Icon.Clock className="icon-header-annotation" />
                  <span>Version</span>
                  <span className="badge badge-danger badge-count">
                    {isShowPublicComment
                      ? versions.public.length
                      : versions.internal.length}
                  </span>
                </button>
              </li>
            )} */}

            {abilityContext.can(
              abilityAction.READ,
              abilityComponent.ANNOTATION_PUBLIC_INTERNAL_COMMENT
            ) &&
              attachmentTypeStartFrom ===
                attachmentTypeStartFromStruct.INTERNAL_REVIEW && (
                <>
                  <li className="list-inline-item">
                    <button
                      type="button"
                      className={`btn btn-header-annotation position-relative ${
                        !isShowPublicComment ? 'active' : ''
                      }`}
                      disabled={isInternalTabDisabled}
                      onClick={() => handleChangeTab('internal')}
                    >
                      <Icon.Lock className="icon-header-annotation" />
                      <span>Internal</span>
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <button
                      type="button"
                      className={`btn btn-header-annotation position-relative ${
                        isShowPublicComment ? 'active' : ''
                      }`}
                      disabled={isPublicTabDisabled}
                      onClick={() => handleChangeTab('public')}
                    >
                      <Icon.Users className="icon-header-annotation" />
                      <span>Public</span>
                    </button>
                  </li>
                </>
              )}

            {/* <li className="list-inline-item">
              <button
                type="button"
                className={`btn btn-header-annotation position-relative ${
                  isShowCommentPanel ? 'active' : ''
                }`}
                onClick={onShowCommentPanel}
              >
                <Icon.MessageCircle className="icon-header-annotation" />
                <span>Comment</span>
                {isShowPublicComment
                  ? publicCommentCount > 0 && (
                      <span className="badge badge-danger badge-count">
                        {publicCommentCount}
                      </span>
                    )
                  : internalCommentCount > 0 && (
                      <span className="badge badge-danger badge-count">
                        {internalCommentCount}
                      </span>
                    )}
              </button>
            </li> */}
            {useClose && (
              <li className="list-inline-item">
                <button
                  type="button"
                  className="btn btn-header-annotation btn-header-annotation-close"
                  onClick={annotationActionContext.onClose}
                >
                  <Icon.X className="icon-header-annotation" />
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
      <hr className="m-0" />
    </div>
  )
}

export default React.memo(HeaderAnnotation)
