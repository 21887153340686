import React, { useState, useEffect } from 'react'
import Joyride, { STATUS } from 'react-joyride'
import {
  selectServiceTourGuideService,
  selectServiceTourGuideStepTwoService,
  selectServiceTourGuideStepTwoCloseService,
} from '../../services/tourGuideService/selectServiceTourGuideService'
import { tourGuideAction } from '../../shared/tourGuideAction'
import { userDetailService } from '../../services/userDetailService/userDetailService'

const SelectServiceTourGuide = ({ match, run }) => {
  const [autoRun, setAutoRun] = useState(false)
  const [steps, setSteps] = useState([
    {
      target: '.select-service-container',
      title: 'Select Service Type',
      content:
        'This page will ask you to select the service to work on as the base of the order.',
      disableBeacon: true,
      type: 'hover',
      isFixed: false,
      spotlightPadding: 0,
    },
    {
      target: '.select-service-container-fake',
      title: 'Fake step',
      content: 'fake step to show skip button',
      disableBeacon: true,
      type: 'hover',
      isFixed: false,
      spotlightPadding: 0,
    },
  ])

  const customStyles = {
    overlay: {
      position: 'absolute',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      zIndex: 999999,
    },
    buttonClose: {
      display: 'none',
    },
  }

  const handleJoyrideCallback = (data) => {
    const { status, lifecycle } = data

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) &&
      lifecycle === 'complete'
    ) {
      if (data.action === 'skip') {
        tourGuideAction('createNewOrder', 'false', () => {
          setAutoRun(false)
          userDetailService.emitReload(true)
        })
      } else {
        if (data.step.lastStep === true) {
          selectServiceTourGuideStepTwoCloseService.emitReload(run)
        } else {
          setAutoRun(false)
        }
      }
    }
  }

  useEffect(() => {
    const subscription = selectServiceTourGuideService
      .reload()
      .subscribe((value) => {
        if (value && run && match && match.path === '/orders/new') {
          setAutoRun(run)
        }
      })

    const subscription2 = selectServiceTourGuideStepTwoService
      .reload()
      .subscribe((callback) => {
        if (run && match && match.path === '/orders/new') {
          setSteps([
            {
              target: '.select-service-button',
              title: 'Select Service Type button',
              content:
                'This button will confirm your service selection. Afterward, you will be routed to the Order Creation page.',
              disableBeacon: true,
              type: 'hover',
              isFixed: false,
              spotlightPadding: 0,
              lastStep: true,
              styles: {
                spotlight: {
                  borderRadius: '10px',
                },
              },
            },
            {
              target: '.select-service-button-fake',
              title: 'Fake step',
              content: 'fake step to show skip button',
              disableBeacon: true,
              type: 'hover',
              isFixed: false,
              spotlightPadding: 0,
              lastStep: true,
            },
          ])

          if (typeof callback === 'function') {
            callback()
          }

          setAutoRun(true)
        }
      })

    return () => {
      subscription.unsubscribe()
      subscription2.unsubscribe()
    }
  }, [run, match])

  return (
    <div className="tour-guide-select-service">
      <Joyride
        callback={handleJoyrideCallback}
        run={autoRun}
        steps={steps}
        styles={customStyles}
        floaterProps={{ disableAnimation: true }}
        showSkipButton={true}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Next',
          next: 'Next',
          skip: 'Skip',
        }}
        continuous={true}
      />
    </div>
  )
}

export default (SelectServiceTourGuide)
