import React, { useState, useRef, useEffect, useContext } from 'react'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import Editor from '@draft-js-plugins/editor'
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from '@draft-js-plugins/mention'
import createEmojiPlugin from '@draft-js-plugins/emoji'
import { Transition } from 'react-transition-group'

import 'draft-js/dist/Draft.css'
import '@draft-js-plugins/mention/lib/plugin.css'
import '@draft-js-plugins/emoji/lib/plugin.css'

import './CommentEditor.scss'
import { saveKeyBindingFn } from '../../shared/utils'
import { ShapeContext } from '../../context/shapeContext'
import { TooltipContext } from '../../context/tooltipContext'

const duration = 150

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  visibility: 'visible',
  opacity: 0,
  height: 0,
}

const transitionStyles = {
  entered: { height: 35, opacity: 1 },
  exited: { height: 0, opacity: 0 },
}

const CommentEditor = ({
  onSend,
  isTooltip = false,
  onTooltipClose = null,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [isDirty, setIsDirty] = useState(false)
  const [inMentioned, setInMentioned] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [{ plugins, MentionSuggestions, EmojiSuggestions }] = useState(() => {
    const mentionPlugin = createMentionPlugin({
      mentionPrefix: '@',
      supportWhitespace: true,
      entityMutability: 'IMMUTABLE',
      mentionComponent: ({ entityKey, children }) => {
        return (
          <span key={entityKey} className="mentioned-user">
            {children}
          </span>
        )
      },
    })
    const { MentionSuggestions } = mentionPlugin
    const emojiPlugin = createEmojiPlugin()
    const { EmojiSuggestions } = emojiPlugin

    const plugins = [mentionPlugin, emojiPlugin]
    return {
      plugins,
      EmojiSuggestions,
      MentionSuggestions,
    }
  })

  const tooltipContextValue = useContext(TooltipContext)
  const selectedAnnotation = tooltipContextValue.selectedAnnotation
  const onUpdateUnsavedCommentSelectedAnnotation =
    tooltipContextValue.onUpdateUnsavedCommentSelectedAnnotation

  const shapeContextValue = useContext(ShapeContext)
  const listMentions = shapeContextValue.listMentions

  const editorRef = useRef()

  useEffect(() => {
    editorRef.current.focus()
  }, [])

  useEffect(() => {
    if (isTooltip) {
      if (
        selectedAnnotation !== null &&
        selectedAnnotation !== undefined &&
        selectedAnnotation.unsavedComment !== undefined &&
        selectedAnnotation.unsavedComment !== ''
      ) {
        const message = JSON.parse(selectedAnnotation.unsavedComment)
        const editorStateComment = EditorState.createWithContent(
          convertFromRaw(message)
        )

        setEditorState(EditorState.moveFocusToEnd(editorStateComment))
        setIsDirty(true)
      } else {
        setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty()))
        setIsDirty(false)
      }
    }
  }, [isTooltip, selectedAnnotation])

  useEffect(() => {
    if (listMentions.length > 0) {
      setSuggestions(listMentions)
    }
  }, [listMentions])

  const handleChange = (newEditorState) => {
    const currentContentTextLength = editorState
      .getCurrentContent()
      .getPlainText().length
    const newContentTextLength = newEditorState
      .getCurrentContent()
      .getPlainText().length

    if (currentContentTextLength === 0 && newContentTextLength === 1) {
      // WORKAROUND: listens to input changes and focuses/moves cursor to back after typing in first character
      setEditorState(EditorState.moveFocusToEnd(newEditorState))
    } else {
      setEditorState(newEditorState)
    }
    setIsDirty(newEditorState.getCurrentContent().hasText())

    if (
      selectedAnnotation &&
      isTooltip &&
      editorState.getCurrentContent().getPlainText() !==
        newEditorState.getCurrentContent().getPlainText()
    ) {
      onUpdateUnsavedCommentSelectedAnnotation(
        JSON.stringify(convertToRaw(newEditorState.getCurrentContent()))
      )
    }
  }

  const handleKeyCommand = (command) => {
    if (
      command === 'editor-send-comment' &&
      editorState.getCurrentContent().hasText()
    ) {
      setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty()))
      setIsDirty(false)

      onSend(editorState, inMentioned)

      return 'handled'
    }

    return 'not-handled'
  }

  const handleEscape = (e) => {
    setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty()))
    setIsDirty(false)

    if (isTooltip && typeof onTooltipClose === 'function') {
      onTooltipClose()
    }
  }

  const handleSendMessage = (e, message) => {
    e.preventDefault()
    e.stopPropagation()
    e.persist()

    // prevent multiple click
    // e.currentTarget.disabled = true
    setIsDirty(false)
    setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty()))

    onSend(message, inMentioned)
  }

  const handleOnAddMention = (mention) => {
    setInMentioned((o) => {
      let pushedMention = o
      if (pushedMention.length > 0) {
        pushedMention = pushedMention.filter((ol) => ol.id !== mention.id)
      }
      return pushedMention.concat(mention)
    })

    // exclude mention twice
    setSuggestions((o) => o.filter((m) => m.id !== mention.id))
  }

  const handleOnSearchChange = (data) => {
    setSuggestions(defaultSuggestionsFilter(data.value, listMentions))
  }

  return (
    <div
      className="comment-editor-wrapper"
      onClick={() => editorRef.current.focus()}
    >
      <div className="position-relative">
        <Editor
          ref={editorRef}
          editorState={editorState}
          onChange={handleChange}
          plugins={plugins}
          placeholder="Add your comment"
          onEscape={handleEscape}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={saveKeyBindingFn}
        />

        <EmojiSuggestions />

        <MentionSuggestions
          onSearchChange={handleOnSearchChange}
          onAddMention={handleOnAddMention}
          suggestions={suggestions}
        />
      </div>

      <Transition in={isDirty} timeout={150} unmountOnExit mountOnEnter>
        {(state) => (
          <div
            className="d-flex align-items-center mt-1"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <div className="d-flex flex-grow-1">
              <span className="hint-bta">CTRL+ENTER to send</span>
            </div>
            <button
              type="button"
              className="btn btn-darkprimary btn-sm"
              onClick={(e) => handleSendMessage(e, editorState)}
            >
              Send
            </button>
          </div>
        )}
      </Transition>
    </div>
  )
}

export default CommentEditor
