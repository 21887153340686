import React, { useState, useEffect, useRef, memo, useCallback } from 'react'
import { Transition } from 'react-transition-group'
import * as Icon from 'react-feather'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import './NotificationList.scss'
import { notificationDisplayDate } from '../../../shared/dateTimeHelper'
import FeatherIcon from '../../FeatherIcon/FeatherIcon'
import Avatar from '../../Avatar/Avatar'
import { ReadSingleNotificationAPI } from '../../../api/Notification/ReadSingleNotification'
import toastCenter from '../../../shared/toastCenter'
import { ReadAllNotificationAPI } from '../../../api/Notification/ReadAllNotification'
import { DeleteAllNotificationAPI } from '../../../api/Notification/DeleteAllNotification'
import { DeleteSingleNotificationAPI } from '../../../api/Notification/DeleteSingleNotification'
import { notificationService } from '../../../services/notificationService/notificationService'
import * as actions from '../../../store/actions/index'
import {
  capitalizeFirstLetter,
  truncateText,
  parseHTML,
} from '../../../shared/utility'
import { ReadGroupNotificationAPI } from '../../../api/Notification/ReadGroupNotification'
import { DeleteGroupNotificationAPI } from '../../../api/Notification/DeleteGroupNotification'
import { notificationDetailService } from '../../../services/notificationService/notificationDetailService'
import Button from '../../Button/Button'
import { widgetType } from '../../../shared/widgetType'
import LoaderComponent from '../../Loader/LoaderComponent/LoaderComponent'

const duration = 250

const bodyRect = document.body.getBoundingClientRect()
const maxHeightNotification = bodyRect.height - 202
const heightNotificationItem = 84

const formatProgres = (text = '') => {
  if (text === 'ready-to-go') {
    return 'Active'
  }
  return text
    .replace(/[-]/, ' ')
    .replace(/[-]/, ' ')
    .replace(/\b\w/g, (l) => l.toUpperCase())
}

const createMessageContent = (data, status) => {
  if (!data.cardNumber || !data.cardTitle) {
    return null
  }

  return (
    <div className="notification-content">
      <span className="font-weight-bold">{data.cardNumber}</span>
      <span className="font-weight-bold">&nbsp;/&nbsp;</span>
      <span className="font-weight-bold" title={data.cardTitle}>
        {truncateText(data.cardTitle, 20)}
      </span>
      {data.cardProgress && (
        <React.Fragment>
          <span className="font-weight-bold">&nbsp;/&nbsp;</span>
          <span className={`font-weight-bold text-${data.cardProgress}`}>
            {formatProgres(data.cardProgress)}
          </span>
        </React.Fragment>
      )}
    </div>
  )
}

const NotificationList = memo(
  ({
    countMessage = 0,
    fetchMessageDetailNotification,
    fetchMessageNotification,
  }) => {
    const navigate = useNavigate()
    const wrapperRef = useRef(null)
    const [isPopoverVisible, setPopoverVisible] = useState(false)
    const [isDetailPage, setIsDetailPage] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [cssNotifWrapper, setCssNotifWrapper] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [pageMeta, setPageMeta] = useState(null)
    const [page, setPage] = useState(0)
    const [pageDetail, setPageDetail] = useState(1)

    const activeClass = isPopoverVisible ? 'active' : ''
    const pageLimit = 10

    const defaultStyle = {
      transition: `all ${duration}ms ease-in-out`,
      visibility: 'visible',
      opacity: 0,
      maxHeight: maxHeightNotification,
    }

    const transitionStyles = {
      entered: { opacity: 1, visibility: 'visible' },
      exited: { opacity: 0, visibility: 'hidden' },
    }

    const handlePopoverMenu = () => {
      setPopoverVisible(!isPopoverVisible)
      setIsDetailPage(false)
    }

    const requestNotifications = useCallback(
      (params = null, cb = null) => {
        fetchMessageNotification(params, (response) => {
          if (response && response.success) {
            if (response.data.length) {
              if (typeof response.meta !== 'undefined') {
                setPageMeta(response.meta)
              } else {
                setPageMeta(null)
              }

              if (
                typeof response.meta !== 'undefined' &&
                typeof response.meta.page !== 'undefined'
              ) {
                setPage(response.meta.page)
              } else {
                setPage(0)
              }
            }

            if (typeof cb === 'function') {
              cb(response.data)
            }
          } else {
            cb()
          }
        })
      },
      [fetchMessageNotification]
    )

    const requestDetailNotifications = useCallback(
      (group, params = null, cb = null) => {
        fetchMessageDetailNotification(group, params, (response) => {
          if (response && response.success) {
            if (typeof response.meta !== 'undefined') {
              setPageMeta(response.meta)
            } else {
              setPageMeta(null)
            }

            if (
              typeof response.meta !== 'undefined' &&
              typeof response.meta.page !== 'undefined'
            ) {
              setPageDetail(response.meta.page)
            } else {
              setPageDetail(1)
            }

            if (typeof cb === 'function') {
              cb(response.data)
            }
          } else {
            cb()
          }
        })
      },
      [fetchMessageDetailNotification]
    )

    useEffect(() => {
      const subscription = notificationService
        .reload()
        .subscribe((isReload) => {
          if (isReload) {
            setIsLoading(true)
            requestNotifications(null, (response) => {
              setIsLoading(false)
              setNotifications(response)
            })
          } else {
            requestNotifications(null, (response) => {
              setNotifications(response)
            })
          }
        })

      return () => {
        subscription.unsubscribe()
      }
    }, [requestNotifications])

    useEffect(() => {
      const subscription = notificationDetailService
        .reload()
        .subscribe((data) => {
          if (data.val) {
            setIsLoading(true)
            requestDetailNotifications(data.group, null, (response) => {
              setIsLoading(false)
              setNotifications(response)
            })
          } else {
            requestDetailNotifications(data.group, null, (response) => {
              setNotifications(response)
            })
          }
        })

      return () => {
        subscription.unsubscribe()
      }
    }, [requestDetailNotifications])

    useEffect(() => {
      if (isPopoverVisible) {
        setIsLoading(true)
        requestNotifications(
          {
            limit: pageLimit,
          },
          (response) => {
            if (response) {
              setNotifications(response)
            }
            setIsLoading(false)
          }
        )
      }
    }, [isPopoverVisible, requestNotifications])

    useEffect(() => {
      if (page > 1) {
        setIsButtonLoading(true)
        requestNotifications(
          {
            page: page,
            limit: pageLimit,
          },
          (response) => {
            if (response) {
              setNotifications((x) => {
                return [...x, ...response]
              })
            }
            setIsButtonLoading(false)
          }
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    useEffect(() => {
      if (pageDetail > 1) {
        setIsButtonLoading(true)
        requestDetailNotifications(
          notifications[0].group,
          {
            page: pageDetail,
            limit: pageLimit,
          },
          (response) => {
            if (response) {
              setNotifications((x) => {
                return [...x, ...response]
              })
            }
            setIsButtonLoading(false)
          }
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDetail])

    useEffect(() => {
      if (
        notifications.length > 0 &&
        notifications.length * heightNotificationItem >
          maxHeightNotification - 65
      ) {
        setCssNotifWrapper({ height: `${maxHeightNotification - 65}px` })
      } else {
        setCssNotifWrapper(null)
      }
    }, [notifications])

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    })

    const handleClickOutside = (e) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setPopoverVisible(false)
        setIsDetailPage(false)
      }
    }

    const handleReadSingleNotification = (e, id, group) => {
      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }

      const readSingleNotificationApi = new ReadSingleNotificationAPI()
      const onNext = (response) => {
        if (!response.success) {
          toastCenter.messageServerError()
        }
      }
      const onComplete = () => {
        notificationDetailService.emitReload(false, group)
      }
      const onError = () => {
        toastCenter.messageServerError()
      }

      readSingleNotificationApi.subscribe(id, null, onNext, onComplete, onError)
    }

    const handleReadGroupNotification = (e, group) => {
      e.stopPropagation()
      e.preventDefault()

      const readGroupNotificationApi = new ReadGroupNotificationAPI()
      const onNext = (response) => {
        if (!response.success) {
          toastCenter.messageServerError()
        }
      }
      const onComplete = () => {
        notificationService.emitReload(false)
      }
      const onError = () => {
        toastCenter.messageServerError()
      }

      readGroupNotificationApi.subscribe(group, onNext, onComplete, onError)
    }

    const handleReadAllNotification = () => {
      const readAllNotificationApi = new ReadAllNotificationAPI()
      const onNext = (response) => {
        if (!response.success) {
          toastCenter.messageServerError()
        }
      }
      const onComplete = () => {
        notificationService.emitReload(true)
      }
      const onError = () => {
        toastCenter.messageServerError()
      }

      readAllNotificationApi.subscribe(null, onNext, onComplete, onError)
    }

    const handleDeleteSingleNotification = (e, id, group) => {
      e.stopPropagation()
      e.preventDefault()

      const deleteSingleNotificationApi = new DeleteSingleNotificationAPI()
      const onNext = (response) => {
        if (!response.success) {
          toastCenter.messageServerError()
        }
      }
      const onComplete = () => {
        notificationDetailService.emitReload(false, group)
      }
      const onError = () => {
        toastCenter.messageServerError()
      }

      deleteSingleNotificationApi.subscribe(id, onNext, onComplete, onError)
    }

    const handleDeleteGroupNotification = (e, group) => {
      e.stopPropagation()
      e.preventDefault()

      const deleteGroupNotificationApi = new DeleteGroupNotificationAPI()
      const onNext = (response) => {
        if (!response.success) {
          toastCenter.messageServerError()
        }
      }
      const onComplete = () => {
        notificationService.emitReload(false)
      }
      const onError = () => {
        toastCenter.messageServerError()
      }

      deleteGroupNotificationApi.subscribe(group, onNext, onComplete, onError)
    }

    const handleDeleteAllNotification = () => {
      const deleteAllNotificationApi = new DeleteAllNotificationAPI()
      const onNext = (response) => {
        if (!response.success) {
          toastCenter.messageServerError()
        }
      }
      const onComplete = () => {
        notificationService.emitReload(true)
      }
      const onError = () => {
        toastCenter.messageServerError()
      }

      deleteAllNotificationApi.subscribe(onNext, onComplete, onError)
    }

    const handleNavigateNotification = (notification) => {
      if (notification.data.target) {
        switch (notification.data.target) {
          case 'order-detail':
            handleReadSingleNotification(
              null,
              notification.id,
              notification.group
            )
            navigate('/orders/detail/' + notification.data.targetId)
            break
          case 'attachment-annotation':
            handleReadSingleNotification(
              null,
              notification.id,
              notification.group
            )
            const commentId = notification.data.targetId.commentId
              ? '&commentId=' + notification.data.targetId.commentId
              : ''
            const isPublic = notification.data.targetId.public
              ? '&public=' + notification.data.targetId.public
              : ''
            const annotation = notification.data.targetId.annotation
              ? notification.data.targetId.annotation
              : ''
            navigate(
              '/orders/detail/' +
                notification.data.targetId.cardId +
                '/attachments/' +
                notification.data.targetId.attachmentId +
                '?versionId=' +
                notification.data.targetId.versionId +
                commentId +
                isPublic,
              {
                annotation,
                commentId: commentId,
                versionId: notification.data.targetId.versionId,
              }
            )
            break
          default:
            break
        }

        setIsDetailPage(false)
        setPopoverVisible(false)
      } else {
        handleReadSingleNotification(null, notification.id, notification.group)
      }
    }

    const selectDetailNotification = (group) => {
      setIsLoading(true)
      setIsDetailPage(true)
      requestDetailNotifications(
        group,
        {
          limit: pageLimit,
        },
        (response) => {
          if (response) {
            setNotifications(response)
          }
          setIsLoading(false)
        }
      )
    }

    const handleBackFromDetailPage = () => {
      setIsLoading(true)
      requestNotifications(
        {
          limit: pageLimit,
        },
        (response) => {
          if (response) {
            setNotifications(response)
          }
          setIsLoading(false)
          setIsDetailPage(false)
        }
      )
    }

    const getClassFromWidgetType = (type) => {
      if (type) {
        switch (type) {
          case widgetType.orderCommentCustomer:
            return 'highlight-cyan'
          case widgetType.orderCommentUser:
            return 'highlight-yellow'
          default:
            return 'no-highlight'
        }
      }
      return 'no-highlight'
    }

    const renderNotificationLists = (
      isDetailPage,
      notifications,
      page,
      pageDetail,
      pageMeta,
      isButtonLoading
    ) => {
      if (isDetailPage) {
        return (
          <React.Fragment>
            <div className="back-btn-wrapper">
              <button
                type="button"
                className="btn btn-back-notification"
                onClick={handleBackFromDetailPage}
              >
                <Icon.ArrowLeft className="feather" /> All Notifications
              </button>
            </div>
            <ul className="list-unstyled m-0 test">
              {notifications.map((notification, idx) => (
                <li
                  key={idx}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleNavigateNotification(notification)}
                >
                  <div
                    className={`notification-item d-flex flex-row ${getClassFromWidgetType(
                      notification.data.widgetType
                    )} ${!notification.isRead ? 'notification-unread' : ''}`}
                  >
                    <div className="d-flex">
                      <Avatar
                        src={notification.avatar}
                        user={{ firstname: notification.username }}
                        radius={50}
                      />
                    </div>
                    <div className="position-relative ml-4 d-flex w-100">
                      <div className="notification-main">
                        <div>
                          <span className="font-weight-bold">
                            {capitalizeFirstLetter(notification.title)}
                          </span>
                        </div>
                        <div>
                          <span
                            className="text-grey"
                            style={{ wordBreak: 'break-word' }}
                            dangerouslySetInnerHTML={{
                              __html: parseHTML(notification.message),
                            }}
                          />
                        </div>
                        <div className="">
                          {createMessageContent(
                            notification.data,
                            notification.status
                          )}
                        </div>
                        <div className="">
                          <span className="text-grey">
                            {notificationDisplayDate(
                              new Date(notification.created)
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="notification-action d-flex flex-column align-items-end">
                        {notification.read ? (
                          <button
                            type="button"
                            className="btn btn-icon btn-unread-notif"
                            disabled
                          >
                            <Icon.Circle className="feather" />
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-icon btn-read-notif"
                            title="Mark as read"
                            onClick={(e) =>
                              handleReadSingleNotification(
                                e,
                                notification.id,
                                notification.group
                              )
                            }
                          >
                            <Icon.Circle className="feather" />
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-icon text-danger btn-remove-notif"
                          title="Delete"
                          onClick={(e) =>
                            handleDeleteSingleNotification(
                              e,
                              notification.id,
                              notification.group
                            )
                          }
                        >
                          <Icon.X className="feather" />
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {typeof pageMeta !== 'undefined' &&
              pageMeta !== null &&
              typeof pageMeta.totalPages !== 'undefined' &&
              pageMeta.totalPages !== undefined &&
              pageDetail < pageMeta.totalPages && (
                <div className="d-flex">
                  <Button
                    isLoading={isButtonLoading}
                    onClick={() => setPageDetail((x) => x + 1)}
                    className="btn-load-more"
                    spinnerColor="#4d5256"
                  >
                    Load More
                  </Button>
                </div>
              )}
          </React.Fragment>
        )
      }

      return (
        <React.Fragment>
          <ul className="list-unstyled m-0">
            {notifications.map((notification, idx) => (
              <li
                key={idx}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  notification.totalMessage > 1
                    ? selectDetailNotification(notification.group)
                    : handleNavigateNotification(notification)
                }
              >
                <div
                  className={`notification-item d-flex flex-row ${getClassFromWidgetType(
                    notification.data.widgetType
                  )} ${!notification.isRead ? 'notification-unread' : ''}`}
                >
                  <div className="d-flex">
                    <Avatar
                      src={notification.avatar}
                      user={{ firstname: notification.username }}
                      radius={50}
                    />
                  </div>
                  <div className="position-relative ml-4 d-flex w-100">
                    <div className="notification-main">
                      {notification.totalMessage > 1 && (
                        <span
                          className="text-grey"
                          style={{ fontStyle: 'italic' }}
                        >
                          There are {notification.totalMessage} notifications to
                          read:
                        </span>
                      )}
                      <div
                        className={
                          notification.totalMessage > 1
                            ? 'latest-notification'
                            : ''
                        }
                      >
                        <div>
                          <span className="font-weight-bold">
                            {capitalizeFirstLetter(notification.title)}
                          </span>
                        </div>
                        <div>
                          <span
                            className="text-grey"
                            style={{ wordBreak: 'break-word' }}
                            dangerouslySetInnerHTML={{
                              __html: parseHTML(notification.message),
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        {createMessageContent(
                          notification.data,
                          notification.status
                        )}
                      </div>
                      <div className="">
                        <span className="text-grey">
                          {notificationDisplayDate(
                            new Date(notification.created)
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="notification-action d-flex flex-column align-items-end">
                      {notification.totalRead === notification.totalMessage ? (
                        <button
                          type="button"
                          className="btn btn-icon btn-unread-notif"
                          disabled
                        >
                          <Icon.Circle className="feather" />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-icon btn-read-notif"
                          title="Mark all as read"
                          onClick={(e) =>
                            handleReadGroupNotification(e, notification.group)
                          }
                        >
                          <Icon.Circle className="feather" />
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-icon text-danger btn-remove-notif"
                        title="Delete all"
                        onClick={(e) =>
                          handleDeleteGroupNotification(e, notification.group)
                        }
                      >
                        <Icon.X className="feather" />
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {typeof pageMeta !== 'undefined' &&
            pageMeta !== null &&
            typeof pageMeta.totalPages !== 'undefined' &&
            pageMeta.totalPages !== undefined &&
            page < pageMeta.totalPages && (
              <div className="d-flex">
                <Button
                  isLoading={isButtonLoading}
                  onClick={() => setPage((x) => x + 1)}
                  className="btn-load-more"
                  spinnerColor="#4d5256"
                >
                  Load More
                </Button>
              </div>
            )}
        </React.Fragment>
      )
    }

    return (
      <div className="top-link-user notification-nav" ref={wrapperRef}>
        <button
          type="button"
          className={`btn btn-icon btn-top-nav ${activeClass}`}
          onClick={handlePopoverMenu}
        >
          <FeatherIcon name="bell" className="feather" />
          {countMessage > 0 && (
            <span className="badge badge-danger badge-top-link">
              {countMessage > 9 ? '9+' : countMessage}
            </span>
          )}
        </button>
        <Transition
          in={isPopoverVisible}
          timeout={0}
          unmountOnExit
          mountOnEnter
        >
          {(state) => (
            <div
              className="popover-notification-toolbar"
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <div className="d-flex flex-column pt-4 pr-4 pl-4">
                <div className="d-flex flex-row align-items-end">
                  <div className="d-flex">
                    <span className="label-notification">Notification</span>
                  </div>
                  {notifications && notifications.length > 0 && (
                    <div className="ml-auto d-flex">
                      <button
                        type="button"
                        className="btn btn-notification"
                        onClick={handleDeleteAllNotification}
                      >
                        Delete All
                      </button>
                      <span className="btn-notification-separator">|</span>
                      <button
                        type="button"
                        className="btn btn-notification"
                        onClick={handleReadAllNotification}
                      >
                        Mark All as Read
                      </button>
                    </div>
                  )}
                </div>
                <hr className="ml-0 mr-0" />
              </div>
              <div
                className="d-flex flex-column notification-wrapper"
                style={cssNotifWrapper}
              >
                <div className="pb-4 pl-4 pr-4 position-relative">
                  {isLoading && (
                    <div
                      className="d-flex flex-column align-items-center justify-content-center w-100 position-relative"
                      style={{ height: '255px' }}
                    >
                      <LoaderComponent loading={isLoading} />
                    </div>
                  )}
                  {!isLoading &&
                    notifications.length > 0 &&
                    renderNotificationLists(
                      isDetailPage,
                      notifications,
                      page,
                      pageDetail,
                      pageMeta,
                      isButtonLoading
                    )}

                  {!isLoading && notifications.length === 0 && (
                    <div
                      className="d-flex flex-column justify-content-center align-items-center"
                      style={{ height: '255px' }}
                    >
                      <div className="d-block text-center">
                        <Icon.Bell className="text-gray feather-50" />
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center pt-3">
                        <span className="text-grey">No notification yet!</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </Transition>
      </div>
    )
  }
)

const mapStateToProps = (state) => {
  return {
    countMessage: state.notification.count,
    notificationMessages: state.notification.messages,
    notificationMeta: state.notification.meta,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMessageDetailNotification: (group, params, callback) =>
      dispatch(actions.fetchMessageDetailNotification(group, params, callback)),
    fetchMessageNotification: (params, callback) =>
      dispatch(actions.fetchMessageNotification(params, callback)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList)
